.heading {
  text-align: center;
}

.flexcont {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-inline: auto;
  justify-content: center;

}

.flexcont > .toptools {
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(126, 123, 123, 0.2);
  transition: 0.3s;
  width: 20%;
  margin: 35px;
  padding: 15px 30px;
  text-align: center;
  font-size: 30px;
}
 
.flexcont > .toptools:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.cardtext {
  color: var(--gray-gray-300, #a7a7a7);
  font-size: 14.921px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.btn {
  text-decoration: none;
  color: #ff5a67;
  font-size: 1.0rem;
  font-style: normal;
  font-weight: 500;
  background: #fff0f0;
  padding: 3% 20%;
  border-radius: 50px;
  cursor: pointer;
}
.btn:hover {
  font-weight: 600;
}
 
@media (max-width: 1250px) {
  .btn{
 font-size: 0.7rem; 
  }

}

/* @media (max-width: 1255px) {
  .arrow{
display: none;
font-size: 0.8rem;  }

} */
@media (max-width: 768px) {
  .flexcont > .toptools {
    border-radius: 20px;
    background: #ffffff;

    width: 100%;
    margin: 40px;
    padding: 10px;

    text-align: center;
    line-height: 75px;
    font-size: 30px;
  }

  .btn {
 font-size: 1.1rem;
    padding: 3% 17%;
 
  }
}
