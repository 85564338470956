 .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
   .card {
    width: 100%;
    max-width: 1000px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
  
  }
  
   .title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .tabsList {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0;
  }
  
  .tabButton {
    background-color: transparent;
    border: 1px solid #ccc;
    color: #333;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 20px;
  }
  
  .tabButton:hover {
    background-color: #f1f1f1;
  }
  
  .activeTab {
    background-color: #f44336;  
    color: white;
    border-color: #f44336;  
  }
  
  .tabButton:focus {
    outline: none;
  }
  
   .tabContent {
    padding: 10px 0;
 
  }
  
   .profileImageContainer {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profileImage {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin: 0 auto;
  }
  
  .uploadButton {
    margin-top: 10px;
    padding: 8px 12px;
    font-size: 0.875rem;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 auto;
  }
  
  .imageNote {
    font-size: 0.75rem;
    color: #888;
  }
  
   .form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .input {
     padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s ease;
 

  }
  
  .input:focus {
    border-color: #ff4d4d;
  }
  
  /* Button Container */
  .buttonContainer {
    text-align: center;
    margin-top: 20px;
  }
  
  .saveButton {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #ff4d4d;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .saveButton:hover {
    background-color: #e60000;
  }
  
  /* Subtitle */
  .subTitle {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .showPasswordButton {
    position: relative;
    background-color: #e60000;
    border: none;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s ease;
    border-radius: 8px;
    padding: 10px;

  }

  .tabContents {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: auto;
    overflow: auto;
    height: 80vh;
  }
  
  .subTitle {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .passwordField {
    position: relative;
    margin-bottom: 20px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 5px;
    outline: none;
  }
  
  .input:focus {
    border-color: #007bff;
  }
  
  .showPasswordButton {
    position: absolute;
    top: 4px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #888;
  }
  
  .showPasswordButton i {
    font-size: 20px;
  }
  
  .buttonContainer {
    text-align: center;
  }
  
 
  
  .accountDetail {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .subTitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .subscriptionDetail {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .subscriptionDetail h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .subscriptionDetail p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .subscriptionDetail ul {
    list-style-type: none;
    padding: 0;
  }
  
  .subscriptionDetail ul li {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .subscribeButton {
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .subscribeButton:hover {
    background-color: #0056b3;
  }
  

/* 
  --- */

  /* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  padding: 20px;
}

/* Centering the Card */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 10px;
}

.card {
  width: 100%;
  max-width: 1100px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* Title */
.title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

/* Tabs */
.tabsList {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 10px 0;
}

.tabButton {
  background-color: transparent;
  border: 1px solid #ccc;
  color: #333;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 5px;
}

.tabButton:hover {
  background-color: #f1f1f1;
}

.activeTab {
  background-color: #f44336;
  color: white;
  border-color: #f44336;
}

.tabButton:focus {
  outline: none;
}

/* Tab Content */
.tabContent {
  padding: 10px 0;
}

/* Profile Image Section */
.profileImageContainer {
  text-align: center;
  margin-bottom: 20px;
}

.profileImage {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin: 0 auto;
}

.uploadButton {
  margin-top: 10px;
  padding: 8px 12px;
  font-size: 0.875rem;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
}

/* Form */
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
}

.input:focus {
  border-color: #ff4d4d;
}

/* Buttons */
.buttonContainer {
  text-align: center;
  margin-top: 20px;
}

.saveButton {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #ff4d4d;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.saveButton:hover {
  background-color: #e60000;
}

.showPasswordButton {
  background-color: #e60000;
  border: none;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s ease;
  border-radius: 8px;
  padding: 10px;
}

/* Tab Contents */
.tabContents {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.subTitle {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

/* Password Field */
.passwordField {
  position: relative;
  margin-bottom: 20px;
}

.input {
  width: 100%;
}

.showPasswordButton {
  position: absolute;
  top: 4px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #888;
}

.showPasswordButton i {
  font-size: 20px;
}

/* Account Details */
.accountDetail {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* Subscription Section */
.subscriptionDetail {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.subscriptionDetail h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.subscriptionDetail p {
  font-size: 16px;
  line-height: 1.5;
}

.subscriptionDetail ul {
  list-style-type: none;
  padding: 0;
}

.subscriptionDetail ul li {
  font-size: 16px;
  line-height: 1.5;
}

/* Subscription Button */
.subscribeButton {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.subscribeButton:hover {
  background-color: #0056b3;
}

/* RESPONSIVE DESIGN */
@media (max-width: 768px) {
  .card {
    padding: 15px;
  }

  .title {
    font-size: 1.3rem;
  }

  .tabButton {
    font-size: 13px;
    padding: 8px 16px;
  }

  .profileImage {
    width: 80px;
    height: 80px;
  }

  .input {
    font-size: 0.9rem;
    padding: 8px;
  }

  .saveButton {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 5px;
  }

  .card {
    padding: 10px;
  }

  .title {
    font-size: 1.1rem;
  }

  .tabsList {
    flex-direction: column;
    align-items: center;
  }

  .tabButton {
    width: 100%;
    text-align: center;
  }

  .profileImage {
    width: 70px;
    height: 70px;
  }

  .input {
    font-size: 0.85rem;
    padding: 6px;
  }

  .saveButton {
    font-size: 0.85rem;
    padding: 6px 12px;
  }
}
