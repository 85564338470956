.container {
  text-align: center;
  font-family: "Arial", sans-serif;
  padding: 20px;
  max-width: 500px;
  margin: auto;
  height: 90vh;
  overflow: auto;
}
   
  
   .paypalButton {
    background: white;
    border: 2px solid #d3d3d3;
    padding: 12px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .paypalIcon {
    color: #00457c;
    font-size: 22px;
  }
  
   .divider {
    margin: 20px 0;
    font-size: 14px;
    color: gray;
    position: relative;
  }
  
  .divider::before,
  .divider::after {
    content: "";
    display: block;
    width: 40%;
    height: 1px;
    background: #d3d3d3;
    position: absolute;
    top: 50%;
  }
  
  .divider::before {
    left: 0;
  }
  
  .divider::after {
    right: 0;
  }
  
   .creditCardSection {
    text-align: left;
  }
  
  .cardIcons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;  
  }
  
  .creditCardTitle {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 0;
  }
  
  .icons {
    display: flex;
    gap: 10px;
  }
  
  .icons i {
    font-size: 24px;
    color: #0071eb;  
  }
  
   .inputGroup {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .inputBox {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .inputBox i {
    position: absolute;
    left: 10px;
    color: gray;
  }
  
  .inputBox input {
    width: 100%;
    padding: 10px 10px 10px 30px;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    font-size: 14px;
  }
  
   .cardDetails {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  
   .subscribeButton {
    width: 100%;
    background: #ff5a5f;
    color: white;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .subscribeButton:hover {
    background: #e0474c;
  }
  
   .terms {
    font-size: 12px;
    color: gray;
    margin-top: 10px;
  }
  