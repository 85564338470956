.root{
  background-color: #fff7f7 !important;

}
.App {
  /* background-color: #fff7f7 !important; */
}

/* .accordion{
    background-color: #FFF7F7 !important;
} */
.accordion-button {
  background-color: #fff7f7 !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}

.react-pdf__Page__textContent {
  mix-blend-mode: multiply;
  visibility: visible;
}

.signature-tab {
  color: #646464;
  font-size: 18px;
  cursor: pointer;
}

.active-signature-tab {
  position: relative;
  display: inline-block;
  text-align: center;
  color: #242424;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
}

.active-signature-tab::after {
  content: "";
  position: absolute;
  width: 98px;
  height: 4px;
  background-color: #ff6e6c;
  left: 50%;
  transform: translateX(-50%); /* Centers the underline */
  bottom: -14px; /* 8px gap between the text and underline */
}



.signature-pad {
  /* height: 296px; */
  border-radius: 6px;
  border: 3px dashed #ff6e6c;
  background-color: #fff3f2;
  margin: 24px 0px;
  padding: 24px;
}

.color-circle {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  cursor: pointer;
}

.font-select {
  width: 213px;
  height: 46px;
  padding: 4px;
  border-radius: 6px;
  border: 1px solid #a7a7a7;
  color: black;
}
.mdl-js-button{
  cursor: pointer!important;
}

Link{
  text-decoration: none;
}