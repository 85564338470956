.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  width: 50%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.icon {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.details {
  text-align: left;
  background: #f8f8f8;
  padding: 15px;
  border-radius: 8px;
}

.details p {
  margin: 10px 0; 
  font-size: 14px;
  color: #444;
  line-height: 1.6;  
  font-family: Montserrat;
}

.details strong {
  color: #222;
  display: inline-block;
  width: 70%;  
}


.active {
  color: green;
  font-weight: bold;
}

.inactive {
  color: red;
  font-weight: bold;
}

 

.closeFooterButton:hover {
  background: #d84343;
}
.footer {
  display: flex;
  justify-content: flex-end;
  background-color: #f5f5f5;
  padding: 15px;
 }

.closeFooterButton {
  background-color: #ff5b5b;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
}

.closeFooterButton:hover {
  background-color: #e04b4b;
}
@media screen and (max-width: 768px) {
  .modalContent {
    width: 80%; /* Adjust width for tablets */
    padding: 15px;
  }
}

@media screen and (max-width: 480px) {
  .modalContent {
    width: 90%;  
    padding: 10px;
  }

  .closeButton {
    top: 5px;
    right: 10px;
    font-size: 18px;
  }

  .details p {
    font-size: 13px;
  }
}

.downloadButton {
  background: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
  border-radius: 5px;
}

.downloadButton:hover {
  background: #45a049;
}
.watermark {
  position: absolute;
  left: 25%;
  top: 50%;
  transform: translateY(-50%) rotate(-15deg);
  opacity: 0.2;
  z-index: 190;
  pointer-events: none;
  width: 300px;
  height: auto;
}
