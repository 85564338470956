.btndesign{
background-color: #FF6E6C;
border: none;
padding: 15px;
color: white;
border-radius: 10px;
}
.btndesignact
{
background-color: aliceblue;
border: 2px solid #FF6E6C;
color:#FF6E6C;
}