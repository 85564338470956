.select-icon-wrapper {
    position: relative;
    width: 100%; /* Adjust width to fit the container */
  }
  
  .select-with-icon {
    padding-right: 2.5rem; /* Make space for the icon */
  }
  
  .select-icon {
    position: absolute;
    top: 50%; 
    right: 10px; /* Adjust for alignment */
    transform: translateY(-50%);
    pointer-events: none; /* Make sure the icon does not block clicks */
    font-size: 1rem;
    color: #6c757d; /* Icon color */
  }
  *{
    font-family: 'Inter', sans-serif;

  }