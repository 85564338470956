.container {
  text-align: center;
  padding: 20px;
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.plans {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Ensures wrapping on smaller screens */
  gap: 20px;
 }

.plan {
  background: #f8f8f8;
  padding: 19px 11px;
  border-radius: 10px;
  width: 100%;
  max-width: 300px; /* Ensures responsiveness */
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.popular {
  background: #ff6961;
  color: white;
}

.tag,
.tagSave {
  font-size: 12px;
  background: #ff4545;
  color: white;
  padding: 2px 6px;
  border-radius: 5px;
  margin-left: 5px;
}

.tagSave {
  background: #ffaf45;
}

.price {
  font-size: 20px;
  font-weight: bold;
}

.description {
  font-size: 14px;
  margin: 10px 0;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 5px 0;
}

.currentPlanBtn,
.subscribeBtn {
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
}

 .currentPlanBtn {
  background: #ff6961;
  color: white;
}

.subscribeBtn {
   color: rgb(0, 0, 0);
}

/* Responsive Design */
@media (max-width: 768px) {
  .plans {
    flex-direction: column;
    align-items: center;
  }
  
  .plan {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 20px;
  }

  .plan {
    padding: 15px;
  }

  .price {
    font-size: 18px;
  }

  .description {
    font-size: 12px;
  }

  .currentPlanBtn,
  .subscribeBtn {
    font-size: 12px;
    padding: 8px;
  }
}
