.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* Full width of the viewport */
  height: 100vh;
  /* Full height of the viewport */
  background-color: #ffdad9;
  /* Optional background color */
}

.loader-image {
  max-width: 100px;
  /* Adjust the max-width of the image as needed */
}

.loader-title {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  color: #242424;
}

.loader-desc {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  color: #646464;
}