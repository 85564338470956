.container {
  display: flex;
  height: 92vh;
}

.sidebar {
  width: 80px;
  background-color: #2d2d2d;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  transition: width 0.3s;
}

.menuItem {
  width: 100%;
  padding: 11px 0;
  text-align: center;
  color: #ccc;
  font-size: 11px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.menuItem:hover {
  background-color: #555;
  color: white;
}

.active {
  background-color: #555;
  color: white;
  font-weight: bold;
}

.icon {
  font-size: 17px;
  margin-bottom: 5px;
  transition: color 0.3s;
}

.activeIcon {
  color: rgb(255, 255, 255);  
}

.label {
  margin-top: 5px;
}

.content {
  flex: 1;
  padding: 20px;
  background-color: #f4f4f4;
}

 @media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }

  .menuItem {
    padding: 10px 0;
  }

  .icon {
    font-size: 18px;
  }

  .label {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 50px;
  }

  .menuItem {
    padding: 8px 0;
  }

  .icon {
    font-size: 16px;
  }

  .label {
    display: none;  
  }
}
