.container {
    background-color: white;
    padding: 2.5rem 1.25rem;
  }
  
  .textCenter {
    text-align: center;
  }
  
  .heading {
     font-weight: bold;
    color: #2d3748;
    text-transform: uppercase;
  }
  
  .highlight {
    color: #f56565;  
  }
  
  .description {
    color: #718096;  
    margin-top: 0.5rem;
  }
  
  .stepsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2.5rem;
    gap: 2.5rem;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 25%;
    justify-content: center;
  }
  
  .iconContainer {

    background-color: #fed7d7;  
    width: 8rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .icon {
    font-size: 1.5rem;
    color: #4a5568;  
  }
  
  .stepTitle {
    font-size: 1.125rem;
    font-weight: 600;
    color: #2d3748;  
    margin-top: 1rem;
  }
  
  .stepDescription {
    color: #718096;
    margin-top: 0.5rem;
  }
  @media (max-width: 768px) {
    
  .stepsContainer {
 
    gap:12.5rem;
  }
  .stepDescription {
  width: 300px;
  }
  }