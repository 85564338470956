#idlabel {
  font-size: 22px;
  color: black;
  width: 224px;
  height: 65px;
  background: #FF6E6E;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

}

.top-mains {
  align-items: end;
  height: 400px;
}

input[type="file"] {
  display: none;
}

.main-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:100px;
}

.main-heading h1 {
  /* font-size: 82px; */
  font-weight: 600;
  text-transform: capitalize;
}

.main-heading p {
  width: 80%;
  margin-top: 3%;
  font-size: 18px;
  text-align: center;
}

.convert-file {
  margin: 0;
  font-size: 22px;
}

.first-body {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.third-component-desc {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: justify;
  color: #747474;
  margin-left: 38px;
}

.main-box {
  border-radius: 16px;
  background-color: #FFE0E0;
  width: 1408px;
  height: 440px;
  max-width: 80%;
}

.inner-box {
  width: 90%;
  height: 360px;
  border-radius: 12px;
  border: 1px solid #242424;
  /* box-shadow: 0px 26px 50px 0px #2E00001F; */
  background-color: #FFF7F7;
  border-style: dashed;
  border-width: 2px;
}

.middle-para {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #747474;
  width: 50% !important;
}

.middle-para span {
  color: #12a4ed;
  text-decoration: underline;
}

.dropdown-toggle {
  font-family: "Inter" !important;
  font-size: 22px !important;
  font-weight: 400 !important;
  line-height: 27px !important;
  letter-spacing: 0em !important;
  color: #242424 !important;
  border-radius: 8px !important;
  border: 0.5px solid #929291 !important;
  background-color: #FFFCFC !important;
  width: 200px;
  padding: 10px;
}

.btn:hover {
  background-color: #fff;
}

.dropdown {
  display: flex;
  justify-content: center;
}

.btn-primary {
  --bs-btn-active-bg: #fff !important;
}

/* new */
.klZLFX {
  flex: 1 0 auto;
  width: 100%;
}

.iGqNlt {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 66px 0px 36px;
}

.dHcbLE {
  display: flex;
  flex-direction: column;
  /* -webkit-box-align: center; */
  /* align-items: center; */
  padding: 0px 16px 54px;
  width: 33.3333%;

}

.EUhIa {
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #242424;
  /* text-align: center; */
  margin: 0px;
  padding: 12px 0px;
}

.cThAAa {
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  color: #434343;
  text-align: justify;
  margin: 0px;
  max-width: 100%;
}

.frequently {
  width: 50%;
}

.qs {
  width: 50%;
}

.frequently h3 {
  font-size: 44px;
  font-weight: 400;
}

.frequently p {
  font-size: 44px;
  font-weight: 600;

}

@media screen and (max-width:1024px) {
  .dHcbLE {
    width: 33.3333%;
  }

  .klZLFX {
    padding: 0px 30px;
    max-width: 1200px;
    margin: 0px auto;
  }

  .dHcbLE {
    width: 33.3333%;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .dHcbLE {
    width: 50%;
  }
}

@media (min-width: 0px) and (max-width: 639px) {}


/*  */

@media (max-width: 1100px) {
  .main-heading h1 {
    font-size: 55px;
  }

  .main-heading p {
    line-height: 3vh !important;
  }

  .middle-para {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .faq-body {
    flex-direction: column;
  }

  .frequently {
    width: 100%;
  }

  .qs {
    width: 100%;
  }

  .main-heading p {
    width: 100%;
    padding: 0% 2%;
    line-height: 4vh !important;
  }

  .main-heading h1 {
    font-size: 42px !important;
    line-height: 75px;
  }

  .second-heading {
    margin-top: 40px !important;
  }

  .para {
    margin-left: 0;
  }

  .exploreNow-btn {
    margin-left: 0;
  }

  .itemBox {
    display: flex;
    flex-direction: column;
  }

  .container {
    max-width: 100% !important;
  }

  .onlinepdfConvertor {
    display: none;
  }

  .blue {
    display: none;
  }

  .main-box {
    margin: 0;
    width: 100%;
    margin-left: 20px;
    background: none;
  }

  .middle-para {
    width: auto;
  }

  .inner-box {
    padding: 40px;
  }

  .file-uploaded {
    flex-direction: column;
  }

  .dHcbLE {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .main-heading h1 {
    font-size: 25px !important;
  }

  .main-heading p {
    width: 100%;
    line-height: 5vh
  }

  .top-mains {
    height: 400px;

  }

  .dHcbLE {
    width: 100%;
  }
}

.dropdown-toggle{
  width: auto;
}