.container {
    text-align: center;
    font-family: "Arial", sans-serif;
    padding: 20px;
     margin: auto;
  }
  
  .heading {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .logo {
    font-size: 20px;
    font-weight: bold;
  }
  
  .highlight {
    color: #ff5a5f;
  }
  
  .subheading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .description {
    font-size: 14px;
    color: gray;
    margin-bottom: 20px;
  }
  
  .plan {
    border: 2px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    background: #F8F8F8;
    position: relative;
  }
  
  .plan.selected {
    border-color: #ff5a5f;
   }
  
  .annual {
    border-color: #ff5a5f;
    background: rgba(255, 90, 95, 0.05);
  }
  
  .planHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .planType {
    font-size: 20px;
    font-family: Montserrat;
    color: #646464;
    font-weight: bold;
  }
  
  .planPrice {
    font-size: 20px;
     color: #000;
    font-family: Montserrat;
  }
  
  .popularBadge {
    background: #ffffff;
    color: #000;
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 14px;
  }
  
  .saveBadge {
    background: #FFEFBA;
    color: black;
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 14px;
  }
  
  .planDescription {
    font-size: 14px;
    color: gray;
    margin: 10px 0;
  }
  
  .features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .features li {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
  }
  .radioCircle {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  
  .plan.selected .radioCircle {
    background-color: #ff4081;  
    border-color: #ff4081;
  }
  
  .radioCircle::after {
    content: "✓";
    font-size: 12px; /* Adjust size as needed */
    color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
}
  .plan.selected .radioCircle::after {
    opacity: 1;  
  }