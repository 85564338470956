.wrapper {
    display: flex;
    gap: 20px;
    padding: 20px;
    background-color: #fef7f7;
  }
  
  .selectionContainer {
    flex: 2;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 72vh;
    overflow: auto;
  }
  
  .categoryBlock {
    margin-bottom: 20px;
  }
  
  .categoryTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    gap: 10px;
  }
  
  .toolCard {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
  }
  
  .toolCard:hover {
    border-color: #ff6666;
  }
  
  .selected {
    background: #ffe5e5;
    border: 2px solid #ff6666;
  }
  
  .icon {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
  }
  
  .checkmark {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #ff6666;
    color: white;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 50%;
  }
  
 
  
  .selectedTools {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
   }
  
  .selectedList {
    margin-top: 10px;
    max-height: 300px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 10px;
   }
  
  .selectedItem {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 5px;
    gap: 8px;
    justify-content: space-between;
    width: 100%;
    height: 60px;
  }
  
  .selectedIcon {
    width: 40px;
    height: 40px;
  }
  
  .closeBtn {
    background: none;
    border: none;
    color: #ff6666;
    font-size: 14px;
    cursor: pointer;
  }
  
  .closeBtn:hover {
    color: #cc0000;
  }
  
  .footer {
    margin-top: auto; 
    padding-top: 10px;
    text-align: center;
  }
  
  .finishBtn {
    background: #ff6666;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .finishBtn:hover {
    background: #cc0000;
  }
  @media (max-width: 768px) {
    .wrapper {
      flex-direction: column;   
      gap: 10px;
      padding: 10px;
    }
  
    .selectionContainer, .selectedTools {
      width: 100%;
      min-width: unset;  
      overflow: auto;
    }
  
    .grid {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
  
    .selectedList {
      grid-template-columns: repeat(1, 1fr); /* One item per row on small screens */
    }
  
    .finishBtn {
      font-size: 14px;
      padding: 8px 16px;
    }
  }

  