.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 450px;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: red;
  }
  
  h2 {
    margin-bottom: 15px;
    font-size: 22px;
  }
  
  .modeSwitch {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .modeSwitch button {
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background: #f0f0f0;
    transition: 0.3s;
  }
  
  .modeSwitch button.active {
    background: #007bff;
    color: white;
  }
  
  .textInputContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .textInput {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .textStyleSelect {
    padding: 8px;
    border-radius: 5px;
  }
  
  .signatureCanvas {
    border: 2px dashed #ddd;
    background: white;
    cursor: crosshair;
  }
  
  .modalButtons {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
  }
  
  .button {
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background: #007bff;
    color: white;
    transition: 0.3s;
  }
  
  .button:hover {
    background: #0056b3;
  }
  .fileUploadWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px dashed #4a90e2;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 350px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .fileUploadWrapper:hover {
    background-color: #eef5ff;
    border-color: #357edd;
  }
  
  .fileInput {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .fileLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
  
  .fileLabel svg {
    font-size: 40px;
    color: #4a90e2;
    margin-bottom: 8px;
  }
  
  .fileLabel span {
    font-weight: 600;
    color: #4a90e2;
  }
  