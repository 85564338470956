.container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100vh;
   width: 100%;
  padding: 1rem;
}

 .formContainer {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  width: 40%;
  max-width: 500px;
  margin-right: 6rem;
 }

 .subscriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  
  align-items: center;  
  background: #ffeeee;
  padding: 7rem; 
   border-radius: 10px;
  width: 50%;
  height: 100vh;
  text-align: center;
}

.subscriptionTitle {
  margin-bottom: auto;  
  font-size: 1.5rem;
  font-weight: bold;
}

.subscriptionList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.subscriptionList li {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}

 .title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.subtitle {
  text-align: center;
  margin-bottom: 1rem;
}

.loginLink {
  color: #ff6b6b;
  text-decoration: none;
  font-weight: bold;
}

 .inputGroup {
  margin-bottom: 1rem;
}

.input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

/* Error messages */
.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

/* Buttons */
.loginButton {
  width: 100%;
  padding: 0.8rem;
  background: #ff6b6b;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.loginButton:disabled {
  background: #ffb3b3;
  cursor: not-allowed;
}

/* Terms and links */
.termsText {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.link {
  color: #ff6b6b;
  text-decoration: none;
}

  
 
 

.subscriptionList li::before {
  content: '\2713'; /* Checkmark */
  color: #ffffff; /* White checkmark */
  font-weight: bold;
  margin-right: 0.5rem;
  background-color: #ff6b6b; /* Red background */
  border-radius: 50%; /* Makes it circular */
  display: inline-flex; /* Ensures proper alignment */
  align-items: center;
  justify-content: center;
  width: 20px; /* Adjust size */
  height: 20px; /* Adjust size */
  font-size: 14px; /* Adjust font size */
}

 @media (max-width: 1024px) {
  .container {
    flex-direction: column;
    height: auto;
  }

  .formContainer {
    width: 90%;
    max-width: 400px;
    margin-bottom: 2rem;
    margin-right: auto;

  }

  .subscriptionContainer {
    width: 90%;
    height: auto;
    margin-left: 0;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .formContainer {
    width: 100%;
    padding: 1.5rem;
    margin-right: auto;

  }

  .subscriptionContainer {
    width: 100%;
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.2rem;
  }

  .subscriptionTitle {
    font-size: 1.2rem;
  }

  .input {
    padding: 0.6rem;
    font-size: 0.9rem;
  }

  .loginButton {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
}
