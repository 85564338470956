.main{
    /* margin-top: 5vw; */
    padding: 30px;
}
.heading-about{
    font-size: 40px;
    font-weight: 600;
    text-align: left;
    color: #242424;
    line-height: 95px;
    letter-spacing: -2px;
}
.para-about{
    font-size: 20px;
    line-height: 40px;
    /* line-height: 7vh; */
    display: flex;
    text-align: justify;
    /* margin-top: 20px; */
    font-family: "Alexandria";
    font-weight: 300;
    color: #242424;
}
.para-about span{
    font-weight: bold;
}

@media (max-width: 754px) {
    .main{
        margin-top: 10vw;
        padding: 25px !important;
    }
}
