body{
    background: #FFF7F7;
  
  }

.app-container {
   display: flex;
  flex-wrap: wrap;
  margin: 20px;
  gap: 10px;
  background: #FFF7F7;

}

.left-div {
  flex: 1;
  text-align: center;
  position: relative;
  height: 80vh;
  background: #f9f9f9;
 }

.left-div-img {
  max-width: 90%;
  border-radius: 10px;
}

.right-div {
  flex: 2;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
 
 }
.file-uploaded-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
}

.file-uploadeds {
  display: flex;
  height: 90vh;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.file-uploadeds svg {
  max-width: 100%;
  height: auto;
}

.file-uploadeds div {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}
.button-container {
  display: flex;
  justify-content: space-between; 
  align-items: center;           
  width: 100%;                  
  padding: 10px;                 
}
.btn {
  padding: 10px 20px;             
   border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;    
  color: #eaf4ff;     
}
.btnsyu {
position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
    color: red;
    border: 1px solid red;
    padding: 5px;
    border-radius: 10px;
    background: none;
}
 .clo{
  background-color: red;
 }
.clo:hover{
  background-color: white;
  border: 1px solid red;
  color: red;
  transition: 1s;
}
.file-uploads {
  position: relative;
  width: 100%;
  height: 60vh;
   border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  transition: border-color 0.3s ease;
  cursor: pointer;
}

.file-uploads input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-uploads h2 {
  font-size: 1.2rem;
  color: #555555;
  margin-bottom: 10px;
}

.file-uploads .drag-drop-message {
  font-size: 0.9rem;
  color: #777777;
  text-align: center;
}

.file-uploads.drag-active {
  border-color: #007bff;
  background-color: #eaf4ff;
}

.file-uploads:hover {
  border-color: #007bff;
  background-color: #f0f8ff;
}

.questions {
  margin: 20px 0;
  
}

.suggested-question {
  cursor: pointer;
  color: #007bff;
  margin: 5px 0;
}

.suggested-question:hover {
  text-decoration: underline;
}

.app-container {
  display: flex;
  padding: 20px;
}

.left-div,
.right-div {
  flex: 1;
  padding: 10px;
}


.file-upload,
.file-uploadeds {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.chat-box {
  margin-top: 20px;
}

.chat-messages {
  height: 60vh;
    padding: 15px;
   margin-bottom: 10px;
  border-radius: 5px;
  overflow: auto;
}

.chat-message {
  margin: 10px 0;
  padding: 8px;
  border-radius: 5px;
  max-width: 80%;
}

.user-message {
  text-align: right;
  background-color: #d1f7d6;
  color: #000000;
  font-size: 14px;
  margin-left: auto;
  border-radius: 20px;
}

.bot-message {
  text-align: left;
  background-color: #eef1ff;
  color: #555;
  border-radius: 20px;
  font-size: 14px;

 }

.search-bar {
  display: flex;
  align-items: center;
}

.input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.buttonz {
  padding: 10px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background: #ccc;
}

.btn-success {
  background-color: green;
}

.suggested-question {
  cursor: pointer;
   margin: 5px 0;
   color: rgb(79, 76, 76);
  font-size: 0.8rem;
   background:  #f5dada;
   padding: 5px;
   border-radius: 10px;
 
}

.suggested-question:hover {
  text-decoration: underline;
}
.summary-section {
  max-height: 200px;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 1.0rem;

}
.summary-section p {
  word-wrap: break-word;
  line-height: 1.5;
  font-size: 0.8rem;
}
.FileText-section {
  max-height: 76vh;
  overflow-y: auto;
   background-color: #f9f9f9;
   text-align: justify;
}

.loading-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.fa-spin {
  font-size: 2rem;
  color: #007bff;
}

.loading-overlay p {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #555;
}
.btndang b {
  font-size: 1rem;
}

.btndang {
  font-size: 1rem;
  background-color: red;
  padding: 15px;
  width: 30%;
}
 
@media only screen and (max-width: 768px) {
  .hidden {
    display: none;
  }
  .btndang b {
    font-size: 0.8rem;

    
  }
  .btndang {
    width: 80%;
  }
}
.success-btn {
    position: absolute;
    top: 20px;  
    right: 20px; 
    z-index: 10;
     color: red;
    border: 1px solid red;

   }
   .success-btn:hover {
    background: red;
    color: white;

   }
  
  @media (max-width: 768px) {
    .success-btn {
      top: 15px;  
      right: 15px;  
      font-size: 14px;  
      width: 88%;

    }
    .summary-section p {
       font-size:1.2vh;
      }
      .FileText-section p{
        font-size:1.6vh;

        
      }
      .txct{
        font-size: xx-small;
      }
  }
  .btnsubmit{
    border-radius: 50% ;
    height: 50px;
    width: 50px;
   }

   .truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;  
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dots-animation {
    display: inline-block;
    font-size: 16px;
    margin-left: 10px;
  }
  
  .dots-animation span {
    display: inline-block;
    animation: dots 1.5s steps(3, end) infinite;
  }
  
  .dots-animation span:nth-child(1) {
    animation-delay: 0s;
  }
  .dots-animation span:nth-child(2) {
    animation-delay: 0.2s;
  }
  .dots-animation span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes dots {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  