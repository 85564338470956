.main-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #ffdad9;
    }
    
    .center-screen {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0% 30%;
    }
    
    .center-screen img {
    width: 100%;
    }
    
    .center-screen h3 {
    font-size: 40px;
    text-align: center;
    line-height: 70px;
    font-weight: 600;
    font-family: Inter;
    }
    
    .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    }

    .buttons button{
    width: 270px;
    height: 66px;
    background: #FF6E6C;
    border: none;
    border-radius: 14px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: Inter;
    font-weight: 500;
    }
    
    .pdfplexlink {
        text-decoration: none;
        color: #222;
        font-weight: 500;
    }
    
    .pdfplexlink:hover {
        text-decoration: underline;
        font-weight: 600;
    }
    
    /* Large screens and above */
    @media (min-width: 1200px) {
    .center-screen {
    padding: 0% 30%;
    }
    
    .center-screen h2 {
    font-size: 50px;
    line-height: 70px;
    }
    
    .center-screen p {
    font-size: 25px;
    line-height: 40px;
    }
    
    .center-screen span {
    font-size: 18px;
    line-height: 40px;
    }
    
    .buttons button {
    font-size: 18px;
    }
    }
    
    /* Medium screens */
    @media (max-width: 1199px) and (min-width: 992px) {
    .center-screen {
    padding: 0% 20%;
    }
    
    .center-screen h2 {
    font-size: 40px;
    line-height: 60px;
    }
    
    .center-screen p {
    font-size: 20px;
    line-height: 35px;
    }
    
    .center-screen span {
    font-size: 16px;
    line-height: 35px;
    }
    
    .buttons button {
    font-size: 16px;
    }
    }
    
    /* Small screens */
    @media (max-width: 991px) and (min-width: 768px) {
    .center-screen {
    padding: 0% 10%;
    }
    
    .center-screen h2 {
    font-size: 35px;
    line-height: 50px;
    }
    
    .center-screen p {
    font-size: 18px;
    line-height: 30px;
    }
    
    .center-screen span {
    font-size: 14px;
    line-height: 30px;
    }
    
    .buttons button {
    font-size: 14px;
    width: 250px;
    }
    }
    
    /* Extra small screens */
    @media (max-width: 767px) {
    .center-screen {
    padding: 0% 5%;
    }
    
    .center-screen h2 {
    font-size: 30px;
    line-height: 45px;
    }
    
    .center-screen p {
    font-size: 16px;
    line-height: 25px;
    }
    
    .center-screen span {
    font-size: 12px;
    line-height: 25px;
    }
    
    .buttons button {
    font-size: 12px;
    width: 100%;
    }
    }
    
    