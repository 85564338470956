.heading {
    text-align: center;
    text-transform: uppercase;
  }
  
  .flexcont {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-inline: auto;
    justify-content: center;

  }
  
  .flexcont > .toptools {
    border-radius: 20px;
    background: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(126, 123, 123, 0.2);
    transition: 0.3s;
    width: 28%;
    margin: 40px;
    padding: 30px 30px;
    text-align: center;
    font-size: 30px;
  }
  .flexcont > .toptools:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  .cardtext {
    color: var(--gray-gray-300, #a7a7a7);
    font-size: 14.921px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .btn {
    text-decoration: none;
    color: #ff5a67;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    background: #fff0f0;
    padding: 3% 20%;
    border-radius: 50px;
    cursor: pointer;
  }
  
  .btn:hover {
    font-weight: 600;
  }

   .btndiv{
    display: flex;
    justify-content: center;
   }
  .showMoreBtn {
    display: inline-block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1rem;
    width: 400px;
     font-weight: 500;
     border-radius: 50px;
    border: 2px solid #646464;
    color: #646464;
    
    background: #F6F6F6;
     box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
     border-radius: 25px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    border: 2px solid #646464;

   }
  .showMoreBtn:hover {
    background-color: #e64e5c;
    color: #F6F6F6;
    border: 2px solid #cc4452;

     transition: background-color 0.3s ease;

  }
  
  .showMoreBtn:active {
    background-color: #cc4452;
    color: #F6F6F6;
     transition: background-color 0.3s ease;



  }
  .pic{
    width:50px;
  }
  
  @media (max-width: 768px) {
    .flexcont{
        justify-content: center;
    }
    .showMoreBtn{
      margin: 41px 27px;
    }
    .flexcont > .toptools {
      border-radius: 20px;
      background: #ffffff;
  
      width: 100%;
      margin: 40px;
      padding: 10px;
  
      text-align: center;
      line-height: 75px;
      font-size: 30px;
    }
    
  .btn {
 
    padding: 3% 17%;
 
  }

  }
  