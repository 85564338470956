.container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .heading {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .table th {
    background-color: #f4f4f4;
  }
  
  .actionButton {
    margin: 0 5px;
    padding: 5px 10px;
    background: #6c63ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .actionButton:hover {
    background: #5848cc;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 50%;
    text-align: center;
  }
  
  .closeButton {
    margin-top: 15px;
    padding: 10px 20px;
    background: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .closeButton:hover {
    background: darkred;
  }
  