.container {
  display: flex;
  flex-direction: column;
   padding: 20px;
  font-family: Arial, sans-serif;
 }
.maincontainer{
  width:75%;

}
 
.searchbar {
  width: 100%;
   
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;

}

 

.toolscontainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  position: relative; /* Required for absolute positioning of child elements */

  }
  .editIcon {
    position: absolute;
    top: -50px;    right: 10px;
    cursor: pointer;
    font-size: 20px;
    background: white; /* Ensure visibility */
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .editIcon:hover {
    background: #ddd;
  }
  
.toolcard {
  display: flex
;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 10px;
  border: 1px solid #dedede;
  box-shadow: -1px 3px 0px 0px rgb(124 124 124 / 10%);
  cursor: pointer;
  transition: transform 0.2sease;
  width: 250px;
  color: black;
  background: white;
}
.fileinput {
  display: none;
}

 
.toolcard i {
  color: #ff6b6b;
}

.toolcard:hover {
  transform: scale(1.05);
}

.sidebar {
  position: absolute;
  right: 100px;
  top: 83px;
  width: 250px;
}

.sidebarbox {
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.recentitem,
.historyitem {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.viewsummary {
  background: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.viewhistory {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
.containers {
  width: 320px;
  background: white;
  border-radius: 8px;
  padding: 16px;
  font-family: Arial, sans-serif;
}

.section {
  margin-bottom: 20px;
  padding: 10px;
  background: #fff6f6;
  border-radius: 8px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.recentItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid #f2b2b2;
  color: #d9534f;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.recentItem:hover {
  background: #ffdddd;
}

.historyHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewAll {
  font-size: 12px;
  color: red;
  text-decoration: none;
}

.historyList {
  list-style: none;
  padding: 0;
}
.toolIcon{
  height: 30px;
}
.historyItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 14px;
  border-bottom: 1px solid #eee;
}

.summaryButton {
  background: #28a745;
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.summaryButton:hover {
  background: #218838;
}
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
.maincontainer{
  width: 100%;
}
  
}
.centerContainer {
  /* display: flex; */
  display: none;
  justify-content: center;
  align-items: center;
}

.mainBox {
  width: 80%;
   margin: auto;
  padding: 20px;
  background-color: #FFDAD9;
   border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.innerBox {
  width: 100%;
  text-align: center;
  padding: 6%;
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px dashed var(--black-white-black-500, #242424);

}

.gap3 {
  gap: 1rem;
}

.fileUploaded {
  flex-wrap: wrap;
  text-align: center;
}

.uploadButton {
  display: inline-block;
    padding: 6px 30px;
   cursor: pointer;
  background: #FF6E6E;
  text-align: center;
  border-radius: 15px 0px 0px 15px;
}
.fileSelect {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 0px 15px 15px 0px;

}
.uploadButton input {
  display: none;
}

.textMuted {
  color: #6c757d;
  font-size: 0.9rem;
}
.alertContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff3cd;
  color: #856404;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  max-width: 900px;
  margin: 10px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}
.danger {
  background-color: #ffe6e6;
  border: 1px solid red;
}

.alertContainer strong svg {
  width: 30px;
  height: 30px;
}

.alertContent {
  display: flex;
  align-items: center;
  flex: 1;
}
.alertText {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
}
.alertMessage {
  margin-left: 10px;
  font-size: 14px;
}
.btnVerify {
  border: 2px solid #feb465;
  color: #feb465;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
}
.alertButton {
  border: 2px solid #feb465;
  color: #feb465;
  padding: 5px 10px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 14px;
  white-space: nowrap;
}
.btnDanger {
  border: 2px solid red;
  color: red;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 5px;
}
@media (max-width: 600px) {
  .alertContainer {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }

  .alertContent {
    flex-direction: column;
    align-items: center;
  }

  .alertMessage {
    margin: 10px 0;
  }

  .alertButton {
    width: 100%;
    text-align: center;
  }
}
.headings
{
  font-size: 1rem;
  text-align: center;
  font-family: Montserrat;
  font-weight: 600;
}