.top-main {
  margin-top: 5%;
  background-color: #fff7f7;
}
.hero-heading {
  font-size: 80px;
  font-weight: 700;
  text-align: left;
  color: #242424;
  line-height: 95px;
  letter-spacing: -2px;
}

.hero-para {
  width: 68%;
  font-size: 22px;
  margin-bottom: 5%;
  margin-top: 3%;
}
.text-muted {
  text-align: justify;
}

.hero-para span {
  color: red;
  text-decoration: none;
}
/* .hero-para a:hover {
  text-decoration: underline;
} */

/* .uni-padding {
  padding: 2% 6%;
} */
 .Link-tag{
  color: red;
 }
.exploreNow-btn {
  padding: 10px;
  width: 284px;
  height: 65px;
  border-radius: 8px;
  background-color: #ff6e6c;
  border: none;
  font-size: 24px;
  color: #242424;
  cursor: pointer;
  text-decoration: none;
}

.featuredBox {
  border: 0.5px solid #929291;
  border-radius: 12px;
  transition: transform 0.3s ease;
  cursor: pointer;
  width: 32%;
  text-decoration: none;
}

.featuredBox:hover {
  transform: scale(1.05);
}

.featuredBox h1 {
  margin-top: 8%;
  font-size: 32px;
  font-weight: bold;
  color: #242424;
}

.featured-heading {
  font-size: 26px;
  font-weight: 700;
  color: #242424;
  margin-top: 8% !important;
  margin-bottom: 2% !important;
}

.how-btn {
  font-size: 19px;
  color: #242424;
  width: 158px;
  height: 39px;
  opacity: 0.8;
  color: #ff6e6c;
  background: none;
  border: none;
  background-color: #ffeeee;
  border-radius: 4px;
  margin-top: 10%;
}

.step h1 {
  font-size: 50px;
  font-weight: 600;
}

.step p {
  margin-top: 4%;
  font-size: 18px;
}
.heading-tools {
  font-size: 50px;
  font-weight: 600;
  /* margin-top: 25%; */
}
.heading-tools span {
  color: red;
}
.desc {
  font-size: 18px;
  line-height: 33px;
  /* line-height: 7vh; */
  display: flex;
  text-align: justify;
  margin-top: 20px;
  font-family: "Alexandria";
  font-weight: 300;
  color: #242424;
}

.third-component-heading {
  font-size: 24px;
  color: #242424;
}

.trd-component-desc {
  font-size: 18px;
  margin-left: 25px;
  color: #747474;
}
.EUhIa {
  font-family: sans-serif;
  font-size: 22px;
}
.cThAAa{
  font-family: sans-serif;
  font-size: 18px;
}

@media (max-width: 1428px) {
  .itemBox {
    gap: 10px !important;
  }
}
@media (max-width: 1356px) {
  .featuredBox {
    width: 48% !important;
  }
  .hero-heading {
    font-size: 60px;
    line-height: 80px;
  }
  .hero-para {
    font-size: 20px;
  }
}
.heading-tools {
  font-size: 35px;
  font-weight: 700 !important;
}
.step > h1 {
  font-size: 35px;
  font-weight: 700 !important;
}
@media (max-width: 992.99px) {
  .hero-heading {
    font-size: 50px;
    line-height: 65px;
  }
  .hero-para {
    font-size: 16px;
  }
  .heading-tools {
    font-size: 35px;
    font-weight: 700 !important;
  }
  .step > h1 {
    font-size: 35px;
    font-weight: 700 !important;
  }
}

@media (max-width: 767px) {
  .top-main {
    margin-top: 18%;
  }
  .main-heading {
    font-size: 42px !important;
    line-height: 75px;
    margin-left: 0;
  }
  .hero-heading {
    font-size: 36px;
    line-height: 50px;
  }

  .second-heading {
    margin-top: 40px !important;
  }

  .para {
    margin-left: 0;
  }

  .exploreNow-btn {
    margin-left: 0;
    margin-top: 10px !important;
    font-size: 18px;
  }

  .featuredBox {
    width: 100% !important;
    padding: 10px;
  }

  .itemBox {
    display: flex;
    flex-direction: column;
  }

  .container {
    max-width: 100% !important;
  }

  .mobContainer {
    margin-bottom: 0;
    margin-top: 60vw;
  }

  .onlinepdfConvertor {
    height: 180px;
  }
  .header {
    padding: 20px;
  }
  .hero-para {
    width: 100%;
    font-size: 12px;
    margin-bottom: 30px;
  }

  .main-home-top {
    padding-left: 4% !important;
    padding-right: 4% !important;
    padding-top: 4% !important;
  }
  .main-home-bottom {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }
}
@media (max-width: 500px) {
  .top-main {
    margin-top: 27%;
  }
  .main-home-bottom {
    padding-top: 8% !important;
  }
}
.main-home-top {
  background: url("../../assets/Ellipse\ 12.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
