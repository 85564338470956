
.container {
  padding: 50px;
  background-color: #FFF7F7;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* margin-inline: 0px; */
}

.title {
  font-size: 30px;
  font-weight: bold;
  color: #3A3A3A;
  text-align: center;
  margin-bottom: 30px;
}

.loading {
  text-align: center;
  color: #666;
  font-size: 1.2rem;
}

.filtersContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 15px;
}

.filtersLeft {
  display: flex;
  gap: 15px;
}

.filterItem {
  display: flex;
  flex-direction: column;
}

.filterItem label {
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
}

.filterItem select,
.filterItem input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 130px;
}

.exportButtonContainer {
  margin-left: auto;
}

.exportButton {
  background-color: #FF5A67;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 7px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.exportButton:hover {
  background-color: #f33f4e;
}
.tableContainer {
  width: 100%;
  overflow-x: auto; 
  -webkit-overflow-scrolling: touch; 
}

.table {
  width: 100%;
  min-width: 600px; 
  border-collapse: collapse;
  background-color: #FFFFFF;
}

.table th,
.table td {
  padding: 10px 8px;
  font-size: 16px;
  word-wrap: break-word;
  text-align: left;
}

.table th {
  font-size: 16px;
  background-color: #F8F8F8;
  font-weight: 600;
  color: #222222;
}



.table a {
  color: #FF5A67;
  text-decoration: none;
}

.table a:hover {
  text-decoration: underline;
}

.table button {
  background-color: #7BC373;
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.table button:hover {
  background-color: #218838;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.paginationButton {
  background-color: #FF5A67;
  border: 1px solid #eb3948;
  color: #FFFFFF;
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
}

.paginationButton:hover {
  background-color: #fa2c3e;
}

.activePage {
  background-color: #333;
  color: #fff;
  font-weight: bold;
  border: 1px solid #333;
}



/* Media Queries For History Section */

@media (max-width: 430px) {
  .container {
    padding: 10px;
  }

  .title {
    font-size: 20px; 
    margin-bottom: 10px;
  }

  .filtersContainer {
    flex-direction: column;
    gap: 10px; 
  }

  .filtersLeft {
    flex-direction: column;
    gap: 8px;
    width: 100%; 
  }

  .filterItem select,
  .filterItem input {
    width: 100%; 
    padding: 6px;
  }

  .exportButtonContainer {
    margin-left: 0;
    margin-top: 10px;
    width: 100%; 
  }

  .exportButton {
    font-size: 13px; 
    padding: 6px 10px; 
  }


  .table {
    width: 100%;
    overflow-x: auto;
    display: block; 
  }

  .table th,
  .table td {
    padding: 8px 6px; 
    font-size: 12px;
  }

  .table th {
    font-size: 13px;
  }

  .paginationContainer {
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
  }

  .paginationButton {
    padding: 5px 8px; 
    font-size: 12px;
  }

  .paginationButton:hover {
    background-color: #fa2c3e;
  }

  .activePage {
    background-color: #333;
    color: #fff;
    font-weight: bold;
    border: 1px solid #333;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 15px; 
    width: 100%; 
    box-sizing: border-box;
  }

  .title {
    font-size: 22px; 
    margin-bottom: 15px;
    text-align: left; 
  }

  .filtersContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px; 
  }

  .filtersLeft {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .filterItem select,
  .filterItem input {
    width: 100%; 
    box-sizing: border-box;
  }

  .exportButtonContainer {
    margin-left: 0;
    margin-top: 15px;
    width: 100%; 
  }

  .exportButton {
    font-size: 14px;
    padding: 8px 12px;
    width: auto; 
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }


  .table {
    width: 100%; 
    overflow-x: auto; 
    /* display: block; */
  }

  .table th,
  .table td {
    padding: 10px 8px; 
    font-size: 13px;
    word-wrap: break-word; 
  }

  .table th {
    font-size: 14px;
  }

  .paginationContainer {
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
  }

  .paginationButton {
    padding: 6px 10px;
    font-size: 14px;
    margin: 0 5px;
  }


  .paginationButton {
    background-color: #FF5A67;
    border: 1px solid #eb3948;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
  }

  .paginationButton:hover {
    background-color: #fa2c3e;
  }

  .activePage {
    background-color: #333;
    color: #fff;
    font-weight: bold;
    border: 1px solid #333;
  }


  .paginationButton, .exportButton {
    font-size: 14px;
    padding: 8px 12px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 30px;
  }

  .title {
    font-size: 24px;
    margin-bottom: 25px;
    text-align: center;
  }

  .filtersContainer {
    flex-direction: column;
    align-items: center;
  }

  .filtersLeft {
    flex-direction: column;
    gap: 12px;
  }

  .filterItem select,
  .filterItem input {
    width: 100%;
  }

  .exportButtonContainer {
    margin-left: 0;
    margin-top: 15px;
  }

  .exportButton {
    font-size: 15px;
    padding: 9px 14px;
  }

  .table th,
  .table td {
    padding: 14px;
  }

  .table th {
    font-size: 15px;
  }

  .paginationContainer {
    /* flex-direction: column; */
    gap: 10px;
  }

  .paginationButton {
    margin: 5px 0;
  }
}

