.remove-button {
    width: 36px;
    background: initial;
    border: initial;
    margin-left: auto;
    margin-right: 5px;
}

.card-body { 
  
    padding: 10px;
   
}
.imagesizing {
    margin: 5px;
    padding: 10px;
    background-color: aliceblue;
}
.images{
    display: flex;
    flex-wrap: wrap;
}
.card{
    width: 200px;
    /* flex: 1 0 200px; */
}
.card-one {
    margin-left: 2%;
    width: 200px;
    height: 15rem;
    border-radius: 6px;
    border: 2px dashed lightgrey;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-one i {
    margin-top: 5%;
}

.card-one select {
    border: 0;
    box-shadow: none !important;
    color: lightgrey;
    width: 140px;
    font-size: 16px;
    font-weight: 600;
}
.pdf-thumbnail{
    width: 100%;
    object-fit: scale-down;
}

/* Styles for image margins */
.small-margin {
    padding: 10px;
  }
  
  .big-margin {
    padding: 50px;
  }
  
  .no-margin {
    padding: 0;
  }
  
  /* Default image styles */
  .card-img-top {
    display: block;
    width: 100%;
    height: auto;
  }
  
  /* Orientation-specific styles */
  .landscape1{
    width: 100%;
    height: 300px; /* Adjust as needed */
  }
  
  .portrait1 {
    height: auto;
    width: 60%; 
    flex: 1 0 100px;
    height: 217px;
    border-radius: 8px;
    background-color: white;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .potrait2 {
   
        flex: 1 0 100px;
        height: 217px;
        border-radius: 8px;
        background-color: white;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
  }
  
@media screen and (max-width: 1200px) {
    .card{
        /* flex: 1 0 200px; */
    }
}
@media screen and (max-width: 767px){
}

@media screen and (max-width: 575px) {
.card-one {
        height: auto;
    }

}