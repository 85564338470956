.error {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .rightDiv {
    flex: 1;
    background-color: #F2F3F8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .container {
    display: flex;
    height: 100vh;
  }
  
  .leftDiv {
    flex: 1;
    background-color: #FFF7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    height: 100%;
  }
  
  .content {
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  .title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .icons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
  
  .icon {
    font-size: 24px;
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .input {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
  }
  
  .input:focus {
    outline-color: #FF6E6C;
  }
  
  .signupButton {
    padding: 15px;
    background-color: #FF6E6C;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
  }
  
  .signupButton:hover {
    background-color: #e85a4f;
  }
  
  .signup {
    margin-top: 20px;
    font-size: 14px;
  }
  
  .createAccount {
    color: #FF6E6C;
    font-weight: bold;
    cursor: pointer;
  }
  
  .createAccount:hover {
    text-decoration: underline;
  }
  
  .smallText {
    font-size: 12px;
    color: #333;
  }
  
  .link {
    color: #FF6E6C;
    text-decoration: underline;
  }

  .loginLink {
    color: #FF6E6C;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 20px;
    font-weight: bold;
}

.loginLink:hover {
text-decoration: underline;
color: #eb4c4a ;
font-weight: 600;
}
  
  .rightContent {
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  .mainIcon {
    font-size: 60px;
    margin-bottom: 20px;
  }
  
  .workspaceText {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .description {
    font-size: 16px;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .tools {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  .seeAll {
    font-size: 16px;
    color: #FF6E6C;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }
    
  .seeAll:hover {
    text-decoration: underline;
  }
  
  .arrow {
    font-size: 20px;
    color: #FF6E6C;
    cursor: pointer;
  }