.reset-all {
    font-size: 14px;
    font-weight: bold;
  }
  
  .hello {
    font-size: 16px;
    font-weight: normal;
  }
  .box{
    background-color: rgb(255, 255, 255);
  }
  .custom-select {
    position: relative;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23495057" viewBox="0 0 20 20"><path d="M7 7l3-3 3 3M7 13l3 3 3-3"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
  }
  
  .custom-select:hover {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
  }
  
  .custom-select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  }
  