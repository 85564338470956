.cards{
    display: flex;
    width: 392px;
    justify-content: space-between;
    height: 70px;
    background-color: white;
    border-radius: 8px;
    /* flex-wrap: wrap; */
    align-items: center;
}
.card-bodys{
    background-color: #FFDAD9;
    border-radius: 8px;
    width: 100%;
}
.heading{
    font-size: 18px;
    align-items: center;
}