 
.images-side {
    width: 68%;
    /* height: 600px; */
    /* margin-left: 3%; */
}
.main-container{
    height: 93vh;
  }
.popup-box {
    width: 950px;
    border-radius: 16px;
    background-color: #FFFFFF;
}

.modal-heading {
     font-size: 32px;
     line-height: 39.01px;
    color: #242424;
}

/* .modal-container{
    height: 100%;
    width: 100% !important;
} */

.input-modal {
    height: 64px;
    font-family: 'Montserrat';
    font-size: 18px;
     line-height: 21.94px;
    color: #A7A7A7;
    border: 0.5px solid #A7A7A7;
    border-right: 0;
    border-radius: 8px 0px 0px 8px;
}

.modal-icon {
    height: 64px;
    width: 70px;
    background-color: #E9E9E9;
    border: 0.5px solid #A7A7A7;
    border-radius: 0px 8px 8px 0px;
}

.modal-input-title {
    font-family: 'Montserrat';
    font-size: 16px;
     line-height: 19.5px;
    color: #242424;
}

.or {
    font-family: 'Montserrat';
    font-size: 16px;
     color: #C8C8C8;
}

.upload-btn {
    height: 60px;
    width: 251px;
    border-radius: 8px;
    background-color: #FF6E6C;
     font-size: 18px;
     line-height: 21.78px;
    color: #242424;
    border: none;
}

.btn-add {
    width: 118px;
    height: 54px;
    border-radius: 6px;
    padding: 16px 40px 16px 40px;
    border: 1px solid #FF6E6C;
    background-color: #FFDAD9;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 400;
    color: #242424;
}

.modal-desc {
     font-size: 13px;
     line-height: 15.73px;
    color: #646464;
}

.main-body {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
}

.converter-side {
    width: 30%;
    /* height: 600px; */
    background-color: #FFDAD9;
    border-radius: 16px;
 }

.main-container {
    /* margin-top: 4rem; */
    padding: 2rem;
    gap: 20px;
    /* background-color: #fbf5f4; */
}

.page-orientation {
    background-color: #FFF3F2;
    border-radius: 8px;
    margin-top: 6%;
}

.card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.portrait {
    flex: 1 0 100px;
    height: 112px;
    border-radius: 8px;
    background-color: white;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
}

.title-form {
     font-size: 22px;
     color: #FF6E6C;
}

.form-title {
     font-size: 18px;
     color: #242424;
}

.input-form {
    border-radius: 8px 0px 0px 8px;
    height: 60px;
    width: 75% !important;
    border: none;
    background-color: #FFFFFF;
     font-size: 1rem;
     color: #242424;
    margin-right: -10px;
}

.input-formm {
    border-radius: 8px 0px 0px 8px;
    height: 60px;
    width: 100% !important;
    border: none;
    background-color: #FFFFFF;
      color: #242424;
     font-size: 1rem;

}

.refresh-icon {
    height: 60px;
    width: 100px;
    background-color: #FF6E6C;
    border-radius: 0px 8px 8px 0px;
}

.desc-form {
     font-size: 18px;
     color: #242424;
}

.containerr {
    border-top: 1px solid #FF9291;
    border-bottom: 1px solid #FF9291;
}

.landscape {
    flex: 1 0 100px;
    height: 112px;
    border-radius: 8px;
    background-color: white;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
}

.img-body {
    /* width: 130px; */
    height: 112px;
    border-radius: 8px;
    background-color: white;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0 100px;
    border: none;
}

.selected {
    border: 1px solid red;
}

.img-body:hover {
    border: 1px solid red;
}

.portrait>p,
.img-body>p,
.landscape>p {
    margin: 0;
}


.portrait:hover,
.landscape:hover {
    border: 1px solid red;
}

.p-body {
    width: 31px;
    height: 42px;
    border: 4px solid lightgrey;
}

.l-body {
    width: 31px;
    height: 42px;
    rotate: 90deg;
    border: 4px solid lightgrey;


}

.hello {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.form-check-input:checked {
    background-color: #FF6E6C;
    border-color: #FF6E6C;
    box-shadow: none !important;
}

.form-check-input {
    box-shadow: none !important;
}

.btn-convert {
     border-radius: 8px;
    border: none;
    background-color: #1d1d1d;
    font-size: 28px;

}
@media screen and (max-width:1080px) {
    .converter-side {
        width: 100% !important;
    }
}

@media screen and (max-width:767px) {
    .main-body {
        flex-direction: column;
    }

    .converter-side {
        width: 100%;
    }

    .cls-side {
        width: 100%;
    }

    .converter-side {
        width: 100%;
    }
}

@media (max-width:767px) {
    .main-container {
        margin-top: 3rem;
    }
}