.ccc{
background-color: #FFF3F2;
border-radius: 10px;
padding: 1rem;
 }
.imgSplit{
    width:50%;
 }
 .btn{
    background-color: rgb(255, 255, 255);
 }
 .btnc{
    border: 2px solid #FF6E6C;
    color: #FF6E6C;
    background-color: rgb(255, 255, 255);

 }
 .btnc:hover{
    background-color: rgb(224, 224, 224);
    border: 2px solid #FF6E6C;

 }
 .custom-pdf-document .react-pdf__Document {
    display: flex !important;    
  }
 .react-pdf__Document {
   display: flex;
   flex-wrap: wrap;   
   gap: 4rem;
   /* justify-content: space-around; */
   padding: 10px;
}

.react-pdf__Page {
   border: 1px solid #ddd;  
   border-radius: 8px;
   padding: 10px;
   background-color: #f9f9f9;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
   position: relative;

 }

.react-pdf__Page__canvas {
   display: block;
   width: 150px;
   height: 194px;
   margin: 0 auto;
}

/* .react-pdf__Page{

} */
 div span{
   position: relative;
   top: 0px;
 }