
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  width:60%;
  max-width:800px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 10px;
  max-height: 80vh;
  overflow-y: auto;
}

.summaryPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 5px;
}

.summaryText {
  font-size: 18px;
  font-weight: bold;
  align-content: center;
  color: #222222;
}

.modalBody {
  padding: 10px;
}

.conversationItem {
  background: #f9f9f9;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

.questionList {
  padding-left: 20px;
}

.bottomCloseButton {
  background-color: #FF5A67;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 7px 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: 0;
  margin-top: 10px;
}

.bottomCloseButton:hover {
  background-color: darkred;
}


@media screen and (max-width: 768px) {
  .modalContent {
    width: 90%;
    max-width: 400px;
    padding: 15px;
  }

  .summaryText {
    font-size: 16px;
  }

  .bottomCloseButton {
    font-size: 14px;
    padding: 8px 15px;
  }
}

@media screen and (max-width: 480px) {
  .modalContent {
    width: 95%;
    max-width: 350px;
    padding: 10px;
  }

  .summaryPreview {
    gap: 15px;
    padding: 15px;
  }

  .summaryText {
    font-size: 14px;
  }

  .modalBody {
    padding: 8px;
  }

  .bottomCloseButton {
    font-size: 12px;
    padding: 6px 12px;
    width: 100%;
  }
}
