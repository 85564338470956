* {
    font-family: 'Unitea Sans', sans-serif;
}
 
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 20px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
}

.icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-bottom: 8px;
}

.navLinks {
    display: flex;
    align-items: center;
    gap: 50px;
}

.navLinks.mobile {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 60px;
    left: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1000;
}

.additionalClass {
    background-color: rgb(239, 222, 222);
    padding: 5px 15px;
    border-radius: 15px;
}

.navLinks.mobile.open {
    display: flex;
}

.navLink {
    font-size: 18px;
    color: black;
    text-decoration: none;
    cursor: pointer;
    position: relative;
}

.navLink:hover .dropdown {
    display: block;
}

.dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    z-index: 1000;
    min-width: 600px;
    
}

.navLink:hover .dropdown {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 45px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: max-content;
    z-index: 1000;

}

.category {
    margin-bottom: 20px;
}

.categoryTitle {
    font-size: 14px;
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
}

.categoryItems {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
}

.dropdownItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
    border-radius: 8px;
    transition: background-color 0.2s;
 }

.dropdownItem:hover {
    background-color: #f8f8f8;
}

.itemIcon {
    width: 20px;
    height: 20px;
}

.authButtons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.login {
    font-size: 16px;
    font-family: 'Unitea Sans', sans-serif;
    border: 1px solid black;
    border: none;
    color: black;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.login:hover {
background-color: #ffffff;
border: #e03e4e 2px solid;
color:#e03e4e ;
padding: 3px 8px;

}
.signup {
    font-size: 16px;
    background-color: #ff4a57;
    font-family: 'Unitea Sans', sans-serif;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.signup:hover {
    background-color: #e03e4e;
}
.closeButton{
    display: none;
}
.menuIcon {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .menuIcon {
      display: block;
      cursor: pointer;
      font-size: 24px;
      color: #333;  
    }
  
    .navLinks {
      display: none;
    }
  
    .mobileSidebar {
      position: fixed;
      top: 0;
      left: 0;  
      height: 100%;
      width: 80%;
      max-width: 300px;
      background-color: #fff;  
      color: #333;  
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      box-shadow: 4px 0 10px rgba(0, 0, 0, 0.3);
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      overflow-y: auto;
      z-index: 100;
    }
  
    .mobileSidebar.open {
      transform: translateX(0);
    }
  
    .closeButton {
      align-self: flex-end;
      font-size: 24px;
      cursor: pointer;
      color: #333;
      display: block;
    }
  
    .navLink {
      cursor: pointer;
      position: relative;
    }
  
    .dropdown {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #f9f9f9; /* Light background for dropdown */
      padding: 10px;
      border-radius: 4px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 10;
    }
  
    .navLink:hover .dropdown {
      display: block;
    }
  
    .dropdownItem {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 10px;
      text-decoration: none;
      color: #333; /* Text color */
      border-radius: 4px;
    }
  
    .dropdownItem:hover {
      background-color: #f1f1f1; /* Hover effect for dropdown items */
    }
  
    .sidebarOverlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
       z-index: 5;
      display: none;
    }
  
     
  }
  

.convertToPDFDropdown {
    position: absolute;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 10;
}

.convertFromPDFDropdown {
    position: absolute;
    background-color: #fffde7;
    border: 1px solid #fbc02d;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 10;
}

.aiToolsDropdown {
    position: absolute;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 10;
    width: max-content;
}

.dropdownItem {
    padding: 8px 12px;
    color: #333;
    cursor: pointer;
    border-radius: 4px;
}

.dropdownItem:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.navLink:hover .convertToPDFDropdown,
.navLink:hover .convertFromPDFDropdown,
.navLink:hover .aiToolsDropdown {
    display: block;
}
.avatarDropdown {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    border: 2px solid #ddd;
  }
  
  .dropdownMenu {
    display: none; /* Hidden by default; show it as needed */
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 10;
    padding: 10px 0;
    width: 200px;
  }
  .dropdownMenu::before,
.dropdownMenu::after {
  content: '';
  position: absolute;
  top: -10px;  
  right: 10px;  
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ddd transparent;
  display: block;
  width: 0;
}

.dropdownMenu::after {
  top: -9px;  
  border-color: transparent transparent #fff transparent;
}
  .avatarDropdown:hover .dropdownMenu {
    display: block;
  }
  
  .dropdownItems {
    padding: 10px 15px;
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
    font-size: 14px;
    transition: background-color 0.2s ease;
  }
  
  .dropdownItems:hover {
    background-color: #f7f7f7;
  }
  
   .authButtons {
    display: flex;
    gap: 10px;
  }
  
 
  
  
  
  
 
  
   @media (max-width: 768px) {
    .avatarDropdown {
      justify-content: right;
     }
  
    .dropdownMenu {
      width: 200px;
      top: 40px;
       border-radius: 0;
    }
  
    .authButtons {
      flex-direction: column;
      gap: 5px;
    }
  .signup{
    display: none;
  }
  
  }
  .newbtn{
    border: none;
    background-color: #ffffff;
  }