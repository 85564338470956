/* Hero Section */
.hero-section {
  background-color: #FFDAD9;
  padding: 70px 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero-left {
  flex: 1;
  max-width: 50%;
}

.hero-title {
  font-size: 56px;
  font-weight: bold;
  color: #242424;
}

.hero-text {
  font-size: 22px;
  color: #646464;
  margin-top: 20px;
}
.pdfplex-footer-span {
color: #FF5A67;
}

.hero-btn {
  background-color: #FF6E6C;
  color: #FFFFFF;
  border: none;
  padding: 14px 22px;
  border-radius: 10px;
  font-size: 22px;
  margin-top: 20px;
  font-weight: 600;
}

.hero-btn:hover {
  background-color: #e64949;
  font-weight: 700;
}

.hero-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.hero-image {
  max-width: 100%;
  height: auto;
}

/* Footer Section */
.footer-section {
  background-color: #FFF7F7;
}

.subscribe-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  padding: 25px 100px;
  border-radius: 5px;
}

.footer-logo {
  width: 250px;
  height: auto;
}

.subscribe-form {
  display: flex;
  gap: 20px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  border: 2px solid #A7A7A7;
  padding: 12px 20px;
  border-radius: 7px;
  width: 450px;
  background: white;
}

.input-wrapper svg {
  margin-right: 10px;
}

.subscribe-input {
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
}

.subscribe-btn {
  background-color: #FF5A67;
  color: #FFFFFF;
  border: none;
  padding: 6px 20px;
  border-radius: 10px;
  font-weight: 600;
}

.subscribe-btn:hover {
  background-color: #e64949;
  font-weight: 700;
}

/* Footer Links */
.footer-links {
  display: flex;
  justify-content: space-around; 
  gap: 0 100px; 
  margin-top: 20px;
  flex-wrap: wrap;
  text-align: center;
}

.footer-column {
  min-width: 200px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: left;
}

.footer-column h6 {
  font-size: 26px;
  color: #434343;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 8px;
}

.footer-column ul li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  transition: color 0.3s ease-in-out;
}

.footer-column ul li a:hover {
  color: #ff6b6b;
}

.social-media-icons {
  display: flex;
  gap: 15px;
  justify-content: center ;
  /* margin-right: 90px; */
  /* margin-top: 10px; */
}

.social-icon {
  width: 30px;
  height: 30px;
  transition: all 0.3s ease-in-out;
}

.social-icon:hover {
  transform: scale(1.1);
}

.footerimg{
  height: 30px;
width: 30px;
}

.footers-pdf {
  color: #FF5A67;
}
/* Bottom Footer */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-size: 19px;
  padding: 15px 5%;
  flex-wrap: wrap;
}

.footer-bottom .footer-links {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.footer-bottom a {
  color: #333;
  text-decoration: none;
}

.footer-bottom a:hover {
  color: #ff6b6b;
 
}

.footer-copyright {
  white-space: nowrap;
}

/* Responsive */
@media (max-width: 1024px) {
  .subscribe-bar {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 15px;
  }

  .subscribe-form {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .input-wrapper {
    width: 100%;
    max-width: 400px;
  }
  .footer-bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    gap: 10px;
  }

  .footer-bottom .footer-links {
    justify-content: center;
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
  }

  .hero-left {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .hero-right {
    justify-content: center;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    text-align: center;
  }
 

  .footer-bottom {
    flex-direction: column;
    text-align: center;
    padding: 20px;
    gap: 10px;
  }
  .hero-title {
    font-size: 50px;
  }
  .footer-bottom {
    padding: 20px;
  }

  .footer-bottom .footer-links {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .subscribe-bar {
    flex-direction: column;
    padding: 20px;
    gap: 15px;
    text-align: center;
  }

  .input-wrapper {
    width: 100%;
    padding: 10px;
  }

  .subscribe-btn {
    width: 100%;
    padding: 10px;
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
    padding: 15px;
    gap: 10px;
  }
  .hero-title {
    font-size: 40px;
  }
  .footer-bottom {
    padding: 15px;
    gap: 10px;
  }

  .footer-bottom .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .footer-copyright {
    font-size: 16px;
    text-align: center;
    white-space: normal;
  }
 
}
