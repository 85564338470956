.text-toolbar {
    display: flex;
    gap: 8px;
    background-color: #ffe4e4; /* Light pink background */
    padding: 10px;
    border-radius: 5px;
    justify-content: center;
  }
  
  .toolbar-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
  }
  
  .toolbar-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  .toolbar-icon {
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
  }
  
  .toolbar-icon.bold {
    font-weight: bold;
  }
  
  .toolbar-icon.italic {
    font-style: italic;
  }
  
  .toolbar-icon.underline {
    text-decoration: underline;
  }
  
  .toolbar-icon.link {
    font-size: 16px;
  }
  
  .toolbar-icon.block {
    font-size: 20px;
  }
  
  .toolbar-icon.align-left,
  .toolbar-icon.align-center,
  .toolbar-icon.align-right,
  .toolbar-icon.justify {
    font-size: 18px;
  }
/* .pdfdesign{
 
    display: block;
    user-select: none;
    width: 40%;
    height: 55vh;
}
  */
.imgdisplay
{width: 50px;
    height: 50px;
    object-fit: cover;
    border: 4px solid #f9f9f9;
    /* box-shadow: 3px 4px #888888; */
    border-radius: 5px;
}


 {/* <div className="text-toolbar mt-2 d-flex gap-2">
      <button
        type="button"
        className="toolbar-button"
        onClick={() => setTextStyles({ ...textStyles, italic: !textStyles.italic })}
      >
        <i className="toolbar-icon italic">I</i>
      </button>
      <button
        type="button"
        className="toolbar-button"
        onClick={() => setTextStyles({ ...textStyles, bold: !textStyles.bold })}
      >
        <i className="toolbar-icon bold">B</i>
      </button>
      <button
        type="button"
        className="toolbar-button"
        onClick={() => setTextStyles({ ...textStyles, underline: !textStyles.underline })}
      >
        <i className="toolbar-icon underline">U</i>
      </button>
      <button
        type="button"
        className="toolbar-button"
        onClick={() => setTextStyles({ ...textStyles, alignment: "left" })}
      >
        <i className="toolbar-icon align-left">≡</i>
      </button>
      <button
        type="button"
        className="toolbar-button"
        onClick={() => setTextStyles({ ...textStyles, alignment: "center" })}
      >
        <i className="toolbar-icon align-center">≡</i>
      </button>
      <button
        type="button"
        className="toolbar-button"
        onClick={() => setTextStyles({ ...textStyles, alignment: "right" })}
      >
        <i className="toolbar-icon align-right">≡</i>
      </button>
      <button
        type="button"
        className="toolbar-button"
        onClick={() => setTextStyles({ ...textStyles, alignment: "justify" })}
      >
        <i className="toolbar-icon justify">≡</i>
      </button>
    </div> */}