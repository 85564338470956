.paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
     padding: 12px 20px;
    border-radius: 50px;
   }
  
  .paginationButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    color: #ff5a67;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 3px 6px rgba(255, 90, 103, 0.2);
  }
  
  .paginationButton:hover {
    background-color: #ff5a67;
    color: white;
  }
  
  .paginationButton.active {
    background-color: #ff5a67;
    color: white;
    box-shadow: 0 3px 6px rgba(255, 90, 103, 0.5);
  }
  
  .paginationButton.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .paginationDots {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #ff5a67;
    font-weight: bold;
  }
  