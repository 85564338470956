.containers {

  width: 100%;
  height: 100vh;
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
  box-sizing: content-box !important;

}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
 

}

.main {
  display: flex;
  flex-wrap: wrap;
  background-color: #f4f4f4;
  justify-content: space-around;
 }
.form-group {
  box-sizing: content-box !important;
}

.div1 {
  width: 60%;
  margin: 22px 35px;
  display: flex;
  gap: 60px;
}

.div2 {
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  margin: 22px 35px;
  background-color: #FFDAD9;
  border-radius: 8px;
  justify-content: space-between;
}

.contentWrapper {
  width: 100%;
  max-width: 600px;
}

.heading {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.selectWrapper {
  margin-bottom: 20px;
}

.pageSelector {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.canvasWrapper {
  text-align: center;
  margin-bottom: 20px;
}

.signatureCanvas {
  border: 2px dotted #ccc;
  margin-bottom: 20px;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #45a049;
}

.fileUploadWrapper {
  margin-bottom: 20px;
}

.fileInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signaturePreview {
  text-align: center;
}

.previewHeading {
  font-size: 18px;
  color: #444;
  margin-bottom: 10px;
}

.signatureImage {
  cursor: grab;
  margin-top: 10px;
}
.pos{
  
 }
@media (max-width: 768px) {
  .div2 {
    padding: 10px;
  }

  .contentWrapper {
    width: 100%;
    padding: 10px;
  }

  .buttonWrapper {
    flex-direction: column;
    gap: 15px;
  }

  .button {
    width: 100%;
  }

  .signatureCanvas {
    width: 100%;
  }

  .pageSelector {
    width: 100%;
  }
}

@media (max-width: 768px) {

  .div1,
  .div2 {
    width: 100%;
  }
}