.main-top {
    margin-top: 6%;
}

/* .uni-padding {
    padding: 3% 8%;
} */

.adds {
    height: 700px;
    background-color: #FF5A67;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 38px;
    font-weight: bold;
    border-radius: 6px;
}
.file-sec{
    width: 100%;
}
.file-sec h1 {
     font-weight: 600;
}

.file-sec p {
    font-size: 18px;
    font-weight: 600;
    text-decoration: underline;
}

.file-sec p span {
    text-decoration: none;
}

.btn-download {
    width: 350px;
    height: 60px;
    background-color: #FF5A67;
    color: white;
    font-size: 1.3rem;
    font-weight: 500;
    border: none;
    border-radius: 8px;
}

.btn-share {
    width: 150px;
    height: 50px;
     border: 2px solid  #64E856;

    color: #64E856;
    background-color: rgb(255, 255, 255);
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 8px;
}

.btn-shares {
    width: 150px;
    height: 50px;     color: black;
    font-size: 1.2rem;
    font-weight: 500;
    border: none;
    border-radius: 8px;
}
.qrcont{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.qr-code{
    background-color: white;
    width: 350px;
    padding:15px 25px;

}
.btn-downloads {
    width: 90%;
    height: 60px;
     color: #FF5A67;
    font-size: 1.3rem;
    font-weight: 500;
     border-radius: 8px;
     background-color: white;
     border: #FF5A67 2px solid;
}
.qrheading{
    font-size: 1.3rem;
}
@media (max-width: 1100px){
    .file-sec{
        margin-top: 5%;
    }
}

@media (max-width: 767px){
    .adds{
        display: none;
    }
    .file-sec{
        width: 100%;
        margin-top: 16%;

    }
    .cards {
        width: 100% !important;
    }
}
@media (max-width: 500px){
    .file-sec{
        margin-top: 25%;

    }
    .btn-download {
        width: 100%;
    }
    
    .btn-share {
        width: 70%;
    }

    .btn-download {
        width: 70%;
    }
    .file-sec p {
        font-size: 15px;
      
    }
}