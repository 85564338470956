body,
html {

    margin: 0;
}
.body{
    background-color: #FF6E6C;
    height: 90vh;
}
.main {
    height: 100%;
}

.main-Screen {
    height: 100%;
    margin-top: 8%;
}


.main-Screen .left-side {
    width: 70%;
    height: 97%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.main-Screen .left-side .cards-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

.main-Screen .left-side .cards-section .card,
.main-Screen .left-side .cards-section .card-one {
    width: 15rem;
    height: 15rem;
    border-radius: 12px;
    border: 1px solid #FF6E6C;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-Screen .left-side .cards-section .card i,
.main-Screen .left-side .cards-section .card-one i {
    margin-top: 5%;
}

.main-Screen .left-side .cards-section .card img {
    margin-top: 7%;
    width: 100%;
    border-radius: 10px;
}

.main-Screen .left-side .cards-section .card-one {
    border: 1px dashed black;
}

.main-Screen .left-side .cards-section .card-one select {
    border: 0;
    box-shadow: none;
    outline: 0;
    width: 140px;
    font-size: 16px;
    font-weight: 600;
}

.main-Screen .left-side .add-section {
    width: 90%;
    height: 300px;
    border-radius: 12px;
    background-color: #FFE0E0;
}

.main-Screen .right-side {
    width: 30%;
    height: 80vh;
    background-color: #FFE0E0;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.main-Screen .right-side div h2 {
    font-size: 34px;
    line-height: 40px;
    font-weight: 700;
}

.main-Screen .right-side div p {
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
}

.main-Screen .right-side button {
    height: 82px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF6E6C;
    border: 0;
    border-radius: 14px;
    font-size: 24px;
    line-height: 28px;
    font-family: Inter;
}

@media screen and (max-width: 1200px) {
    .main-Screen {
        margin-top: 14%;
    }
    .main-Screen .left-side .cards-section .card,
    .main-Screen .left-side .cards-section .card-one {
        width: 10rem;
        height: 10rem;
    }

    .main-Screen .left-side .add-section {
        height: 200px;
    }

}

@media screen and (max-width: 768px) {
    .main-Screen {
        height: 80vh;
        width: 100%;
        margin-top: 19%;
    }

    .main {
        padding: 2% !important;
    }

    .main-Screen .left-side {
        width: 70%;
        height: auto;
    }

    .main-Screen .right-side {
        width: 30%;
        height: auto;
    }

    .main-Screen .right-side div p {
        font-size: 14px;
        font-weight: 300;
        line-height: 30px;
    }

    .main-Screen .right-side button {
        height: 48px;
        width: 90%;
        display: flex;
        justify-content: center;
        border-radius: 14px;
        font-size: 14px;
        line-height: 20px;
        font-family: Inter;
    }
}

@media screen and (max-width: 575px) {
    .main-Screen {
        flex-direction: column;
        gap: 120px;
        margin-top: 26%
    }

    .main-Screen .left-side .cards-section .card,
    .main-Screen .left-side .cards-section .card-one {
        width: 100%;
        height: auto;
    }

    .main-Screen .left-side,
    .main-Screen .right-side {
        width: 100%;
    }

    .main-Screen .left-side .add-section {
        height: 150px;
    }
}