.cont {
  width: 100%;
  max-width: 2400px;
  margin-inline: auto;
 
}

 .alignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 65vh;  
    width: 100%;
  }
  
   .textCenter {
    text-align: center;
    margin: 0 auto;
  }
  
   .headingTools {
    font-size: 2rem;
    font-weight: bold;
    color: #333;  
  }
  
   .highlight {
    color: #ff5a67;
  }
  
   .description {
    display: block;
    font-size: 1rem;
    color: #666;  
    margin-top: 1rem;
    line-height: 1.5;  
  }
  