.container {
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchContainer {
   display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  width: 50%;
  flex-direction: column;
  align-items: center; 
 }

.imga {
  width: 250px;
  display: block;  
  margin: 0 auto;  
border: #F8F8F8 solid 40px;
border-radius: 50%;
 

}

.input-group {
  width: 100%;
}

.input-group .form-control {
  margin-bottom: 5px;  
}

.input-group-append {
  margin-top: 5px;  
}
 



.resultsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttonBar {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.topButton {
  padding: 10px;
  color: rgb(255, 255, 255);
  background-color: red;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}
.topButtons {
  padding: 7px;
  color: red;
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid red;
}
.topButton:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(248, 6, 6);
  border: 1px solid red;
  padding: 9px;
}
.table-responsive {
  max-height: 400px; /* Adjust height based on your layout */
  overflow-y: auto; /* Allows vertical scrolling */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
}

.table-bordered {
  border: 1px solid #ddd;
}
.resultsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  height: 80vh;
}

.viewSection {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.textSection {
  background-color: #ffffff;

  flex: 1;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  overflow: auto;
  overflow-y: auto;

  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
  flex-grow: 1;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  padding: 10px 10px;
  border-bottom: 1px solid #ddd;
}

.leftButtons {
  display: flex;
  gap: 5px;
}

.barButton {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.barButton:hover {
  background-color: #0056b3;
}

.websiteAddress {
  font-size: 0.9rem;
  color: #555;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 50%;
}

 @media (max-width: 768px) {
  .resultsContainer {
    flex-direction: column;
    height: auto;
  }
.weburl{
width: 100%;
}
  .viewSection,
  .textSection {
    width: 100%;
    height: auto;
  }

  .websiteAddress {
    max-width: 40%;
  }
  .iframe {
    height: 500px;
  }
}
.chatContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 75vh;
  overflow-y: auto;
  padding: 10px;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.chatContainer::-webkit-scrollbar {
  width: 8px;
}

.chatContainer::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.userMessage,
.botMessage {
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  word-wrap: break-word;
  font-size: 14px;
}

.userMessage {
  background-color: #d1f7d6;
  align-self: flex-end;
  color: #333;
}

.botMessage {
  background-color: #eef1ff;
  align-self: flex-start;
  color: #333;
}

.chatInput {
  margin-top: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
  font-size: 14px;
}

.chatInput:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
}

.questionsList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.questionsList li {
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #f0f0f0;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.questionsList li:hover {
  background-color: #d8eaff;
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loadingText {
  font-size: 1.2rem;
  color: #555;
  animation: fadeInOut 2s infinite;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.dottedAnimation {
  display: inline-block;
  width: 1.5rem;
  text-align: left;
}
.line {
   width: 100%;
   margin: 10px 0;  
   text-align: center;
}
.dottedAnimation::after {
  content: "";
  display: inline-block;
  animation: dots 1.5s steps(3, end) infinite;
}

@keyframes dots {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: "";
  }
}

.inputContainer {
  display: flex
  ;
      align-items: center;
      padding: 0.5rem;
      background-color: #f8f9fa;
      border-top: 1px solid #ddd;
      position: sticky;
      bottom: -15px;
      right: 0;
       z-index: 10;
}

.chatInput {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 0.5rem;
}

.sendButton {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sendButton:hover {
  background-color: #0056b3;
}
.inputGroups {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .inputGroups{
    width: 180%;
  }
  .inputContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .chatContainer {
    height: calc(100vh - 50px);
    padding-bottom: 60px;
  }
}
 
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #f0efef;
  transform: translate(-50%, -50%);
   padding: 20px;
  border-radius: 10px;
  z-index: 1000;
    text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: 80vh;
  width: 1050px; 
}

.modal h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.modal button {
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.modal button:hover {
  background-color: #ff3d00;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #171717;
  transition: color 0.2s ease-in-out;
 
  
}

.closeIcon:hover {
  color: #000;
}
@media (max-width: 900px) {
.modal{
  width: 95%;
}
}