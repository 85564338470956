.container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 20px;
    height: auto;
  }
  
   .left, .right {
    flex: 1;
  
  }
  
   .separator {
    width: 2px;
    background-color: #c0c0c0;
    height: 80vh;
    position: absolute;
}
   
  
   @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .separator {
      display: none;
    }
  }
  