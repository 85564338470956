 .centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainBox {
    width: 80%;
     margin: auto;
    padding: 20px;
    background-color: #FFDAD9;
     border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.innerBox {
    width: 100%;
    text-align: center;
    padding: 6%;
    background-color: #ffffff;
    border-radius: 10px;
    border: 2px dashed var(--black-white-black-500, #242424);

}

.gap3 {
    gap: 1rem;
}

.fileUploaded {
    flex-wrap: wrap;
    text-align: center;
}

.uploadButton {
    display: inline-block;
      padding: 18px 30px;
    border-radius: 7px;
    cursor: pointer;
    background: #FF6E6E;
    text-align: center;
}

.uploadButton input {
    display: none;
}

.textMuted {
    color: #6c757d;
    font-size: 0.9rem;
}

.textSuccess {
    color: green;
    font-size: 1rem;
    font-weight: bold;
}

.mainContainer {
    display: flex;
    justify-content: space-between;
}

.flexWrap {
    flex-wrap: wrap;
}

.imagesSide {
    width: 63%;
    padding: 10px;
}

.converterSide {
    width: 30%;
    padding: 10px;
    background: #FFDAD9;
    border-radius: 20px;
    margin: 22px 35px;
}

.sidebar {
     padding: 15px;
    border-radius: 10px;
}

.textCenter {
    text-align: center;
}

.container {
    display: flex;
    gap:70px;
    padding: 10px;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.titleForm {
    font-weight: bold;
    font-size: 1.3rem;
    color: #FF6E6E;
}

.titleDesc {
    font-size: 0.9rem;
    color: #555;
}

.formCheckInput {
    transform: scale(1.2);
}

.btnConvert {
    width: 100%;
    padding: 30px;
    background-color: #FF6E6C;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.4rem;
    cursor: pointer;
}
.customClass{

 gap:1.0rem;
}
 @media (max-width: 768px) {
    .customClass{
        justify-content: center;
        gap:3.4rem;
    }
    .mainBox {
        width: 95%;
        padding: 15px;
    }
    .addmore{
        padding: 4%;
   }
    .innerBox {
        padding: 10px;
    }

    .imagesSide {
        width: 100%;
        order: 2;
    }

    .converterSide {
        width: 100%;
        order: 1;
    }

    .sidebar {
        padding: 10px;
    }

    .btnConvert {
        padding: 10px;
        font-size: 0.9rem;
    }

    .container {
        flex-direction: column;
        gap: 10px;
    }
}