.container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;  }
  
  .div60 {
    flex: 0 0 60%;
     padding: 80px;
    text-align: justify;
   }
  .text{
    width: 77%;
    color: var(--gray-gray-400, #646464);
    font-weight: 400;

    font-size: 1.5rem;

  }
  .imghome{
    width: 570px;
    height: auto;
  }
  .webhead{
    color: #3A3A3A;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}
  .div40 {
    flex: 0 0 40%;
     text-align: center;
   }
  
  .heading {
    text-transform: uppercase;
    font-size: 3.2rem;
  }
  .button{
    text-decoration: none;
    padding: 15px 30px;
    width: 280px;
    height: 65px;
    flex-shrink: 0;
    border-radius: 6.933px;
    background: #FF5A67;
    color: white;
    font-weight: 600;
  }
  .button:hover {
    font-weight: 700;
    background: #f5404f;
  }

   @media (max-width: 768px) {
    .div60, .div40 {
      flex: 0 0 100%;  
    }
  
    .div60 {
      padding: 40px;  
    }
  
    .div40 {
      padding: 20px; 
    }
    .imghome{
      width: 100%;
      height: auto;
    }
.heading{
  font-size: 1.6rem;
}
.text{
  font-size:1rem ;
}

  }
  @media only  screen and (max-width: 1416px) and (min-width: 1032px)  
  {
    .div60 {
      flex: 0 0 42%;
       padding: 80px;
      text-align: justify;
     }
  }

  @media   screen and (max-width: 1032px) and (min-width: 767px)  
  {
    .div60 {
      flex: 0 0 100%;
   
     }
     .div40{
      flex: 0 0 100%;
 justify-content: center;     }
  }

  @media   (max-width: 1700px)   
  {
    .heading {
      text-transform: uppercase;
      font-size: 2.2rem;
    }
  }
