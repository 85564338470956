.containerd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  margin: auto;
  flex-wrap: wrap;
}

.cardss {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.iconContainer {
  width: 180px; 
  height: auto;  
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 100%;
  height: auto;
}

.textContent {
  flex: 1;
  text-align: left;
  margin-left: 5px;  
}

.textContent h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.textContent p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
}

 

  
   @media (max-width: 600px) {
    .container {
      flex-direction: column;
       gap: 10px;
       padding: 10px;
    }
  
    .cardss {
      width: 100%;
      justify-content: center;    }
  
    .textContent {
      text-align: center;
    }
  
    .textContent h3 {
      font-size: 16px;
    }
  
    .textContent p {
      font-size: 13px;
    }
  }
  .hr {
    width: 90%;
    margin: 10px auto;  
    border: none;
    border-top: 2px solid #000000;  
  }
  