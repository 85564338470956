.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: auto;
  padding: 15px;
  align-items: stretch;
  
}

.selectionContainer {
    flex: 1;
    width: 50%;
    background-color: #fff5f5;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    min-height: 450px; 
  }

  .categoryBlock {
    flex-grow: 1;  
  }

.categoryTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}
.toolCard.selected {
    border: 1px solid #ff4d4d;
    background-color: rgba(255, 0, 0, 0.1);
  }
  .checkmark {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #ff4d4d;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
  }
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

 
.toolCard {
    position: relative;
    border: 2px solid #ccc;
    padding: 9px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fff;

    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
.toolCard:hover {
  border-color: #ff4d4f;
  transition: all 0.3s ease-in-out;

}

.selected {
  border-color: #ff4d4f;
  background-color: #ffe6e6;
}

.icon {
  width: 30px;
  height: 30px;
}

.selectedTools {
  flex: 1;
  width: 50%;
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 10px;
}

.selectedList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.selectedItem {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    position: relative;
  }
  
  .selectedIcon {
    width: 30px;
    height: 30px;
    
  }

  .closeBtn {
    background: none;
    border: 1px solid #777;
    font-size: 10px;
    cursor: pointer;
    color: #777;
    margin-left: auto;
    border-radius: 50%;
    width: 15px;  
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
  }
  
  .closeBtn:hover {
    color: #d00;
    border-color: #d00;
    background-color: rgba(255, 0, 0, 0.1);
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

.dot {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.activeDot {
  background-color: #ff4d4f;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.backBtn,
.nextBtn {
  padding: 8px 34px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.backBtn {
  background-color: #ffffff;
  color: #646464;
  border: #646464 2px solid;
}
.backBtn:hover {
     color: #ffffff;
    background-color: #646464;
    border: #ffffff 2px solid;
  }
.nextBtn {
  color: #ff4d4f;
  border: #ff4d4f 2px solid;
}

.nextBtn:hover {
    background-color: #ff4d4f;
    color: white;
 }
@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    gap: 15px;
  }

  .selectionContainer,
  .selectedTools {
    width: 100%;
  }

  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .selectedList {
    grid-template-columns: repeat(2, 1fr);
  }
  .categoryTitle{
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .selectedList {
    grid-template-columns: repeat(1, 1fr);
  }
}
