.images-side {
    width: 68%;
    /* height: 600px; */
    /* margin-left: 3%; */
}

.main-body {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
}

.converter-side {
    width: 30%;
     background-color: #FFDAD9;
    border-radius: 16px;
}

.main-container {
     padding: 2rem;
    gap: 20px;
    /* background-color: #fbf5f4; */
}

.page-orientation {
    background-color: #FFF3F2;
    border-radius: 8px;
    margin-top: 6%;
}

.card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.portrait {
    flex: 1 0 100px;
    height: 112px;
    border-radius: 8px;
    background-color: white;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landscape {
    flex: 1 0 100px;
    height: 112px;
    border-radius: 8px;
    background-color: white;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img-body {
    /* width: 130px; */
    height: 112px;
    border-radius: 8px;
    background-color: white;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0 100px;

}

.selected {
    border: 1px solid red;
}

.img-body:hover {
    border: 1px solid red;
}

.portrait>p,
.img-body>p,
.landscape>p {
    margin: 0;
}


.portrait:hover,
.landscape:hover {
    border: 1px solid red;
}

.p-body {
    width: 31px;
    height: 42px;
    border: 4px solid lightgrey;
}

.l-body {
    width: 31px;
    height: 42px;
    rotate: 90deg;
    border: 4px solid lightgrey;


}

.hello {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.form-check-input:checked {
    background-color: #FF6E6C;
    border-color: #FF6E6C;
    box-shadow: none !important;
}

.form-check-input {
    box-shadow: none !important;
}

.btn-convert {
    height: 68px;
    border-radius: 8px;
    border: none;
    background-color: #FF6E6C;
    font-size: 28px;

}

@media screen and (max-width:767px) {
    .main-body {
        flex-direction: column;
    }

    .converter-side {
        width: 100%;
    }

    .images-side {
        width: 100%;
    }
}
.cdd{
    font-size: small;
    color: rgb(105, 106, 106);
}