
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: #f0efef;
    transform: translate(-50%, -50%);
     padding: 20px;
    border-radius: 10px;
    z-index: 1000;
      text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1050px;
    height: 80vh;
    overflow: auto;
  }
  
  .modal h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  
  .modal button {
    padding: 10px 20px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .modal button:hover {
    background-color: #ff3d00;
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  @media (max-width: 900px) {
    .modal{
      width: 95%;
    }
    }.closeIcon {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      cursor: pointer;
      color: #171717;
      transition: color 0.2s ease-in-out;
     
      
    }
    
    .closeIcon:hover {
      color: #000;
    }