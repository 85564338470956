.container {
    width: 320px;
    background: white;
    border-radius: 8px;
    padding: 16px;
    font-family: Arial, sans-serif;
  }
  
  .section {
    margin-bottom: 20px;
    padding: 10px;
    background: #fff6f6;
    border-radius: 8px;
  }
  
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
  
  .recentItem {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: 1px solid #f2b2b2;
    color: #d9534f;
    padding: 8px;
    border-radius: 5px;
    font-size: 11px;
    cursor: pointer;    text-decoration: none;
 text-align: center;
  }
  
  .recentItem:hover {
    background: #ffdddd;
  }
 
  .historyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .viewAll {
    font-size: 12px;
    color: red;
    text-decoration: none;
  }
  
  .historyList {
    list-style: none;
    padding: 0;
  }
  
  .historyItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    font-size: 14px;
    border-bottom: 1px solid #eee;
  }
  
  .summaryButton {
    background: #28a745;
    color: white;
    border: none;
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .summaryButton:hover {
    background: #218838;
  }
  @media (max-width: 480px) {
    .container {
      max-width: 100%;
      padding: 12px;
    }
  
    .grid {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  
    .recentItem {
      font-size: 12px;
      padding: 6px;
    }
  
    .historyItem {
      font-size: 12px;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .summaryButton {
      width: 100%;
      margin-top: 4px;
    }
  }