.dashboard-layout {
    display: flex;
 }

.sidebar {
    width: 250px;
    background-color: #f8f9fa;
    padding: 20px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
}

.main-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    height: 90vh;
}
