.chatbotContainer {
  max-width: 90%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 15px;
  font-family: Arial, sans-serif;
  background: linear-gradient(135deg, #ffeded, #e6f7ff);
  height: 80vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.imagecont {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.imagecont img {
 width:10%;
 opacity: 0.3;
}
.head{
  color: #393939;

}
.para{
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #666;
}
.chatbotMessages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
}

.chatMessage {
  padding: 12px;
  border-radius: 10px;
  max-width: 90%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  word-wrap: break-word;
}

.userMessage {
  background-color: #d1f7c4;
  align-self: flex-end;
}

.botMessage {
  background-color: #f0f0f0;
  align-self: flex-start;
}

.loadingDots {
  display: flex;
  gap: 3px;
  align-self: flex-start;
  color: #666;
  font-size: 20px;
}

.chatbotInput {
  display: flex;
  gap: 10px;
  padding: 10px 0;
}

.inputField {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.sendButton {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.sendButton:hover {
  background-color: #0056b3;
}

.defaultQuestionsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.defaultQuestionButton {
  padding: 15px 20px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.defaultQuestionButton:hover {
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .chatbotContainer {
    height: 100vh;
  }

  .chatbotMessages {
    height: 60vh;
  }

  .chatMessage {
    font-size: 13px;
  }

  .inputField {
    font-size: 13px;
    padding: 8px;
  }

  .sendButton {
    font-size: 13px;
    padding: 8px 12px;
  }

  .defaultQuestionButton {
    font-size: 13px;
    padding: 8px 12px;
  }
}
@media (max-width: 768px) {
  .defaultQuestionsContainer {
    flex-direction: column;
    gap: 15px;
  }

  .defaultQuestionButton {
    width: 100%;
    max-width: 300px;
    text-align: center;
  }
}
.startConversationText {
  font-size: 18px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
/* Modal.module.css */

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #f0efef;
  transform: translate(-50%, -50%);
   padding: 20px;
  border-radius: 10px;
  z-index: 1000;
    text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: 80vh;
  width: 1050px;
}

.modal h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.modal button {
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.modal button:hover {
  background-color: #ff3d00;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #171717;
  transition: color 0.2s ease-in-out;
 
  
}

.closeIcon:hover {
  color: #000;
}
@media (max-width: 900px) {
.modal{
  width: 95%;
}
}