body {
    /* font-family: sans-serif; */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: white;
  }
  
  .contactUsPage {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    gap: 30px;
  }
  
  .leftSection {
    max-width: 45%;
    padding: 20px;
    background-color: white;
    box-shadow: none;
    border-radius: 0;
  }
  
  .rightSection {
    flex: 1;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(70, 68, 68, 0.2);
  }
  
  /* Left Section */
  .leftSection {
    max-width: 45%;
  }

.faqs h3 {
font-weight: 600;
}

 .leftSection p {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
  }
  
  .faqPage {
    color: #FF5A67;
  }
  .faqPage:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .faqsQuestions h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .emailUs,
  .location {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    margin-top: 14px;
  }
  
  .emailUs p,
  .location p {
    line-height: 1.2; 
    margin: 0;
  }
  
  .iconText {
    display: flex;
    align-items: center;  
    gap: 10px;
  }
  

  .faqsSection {
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(70, 68, 68, 0.2);
    border: none;
  }
  
  .faqsSection h3 {
    font-size: 22px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
  }
  
  .faqsSection ul {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .material-symbols-outlined {
    font-size: 25px;
    color: #333;
    border-radius: 50%;
    padding: 10px;
    background: #f3f3f3; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

h5 {
  font-weight: 600;
}
  .emailLink {
    color: #FF5A67;
    text-decoration: none;
font-weight: 600;
  }
  
  .emailLink:hover {
    text-decoration: underline;
    font-weight: 700;
  }
  
  /* Right Section */
  .rightSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .rightSection h2 {
    font-size: 24px;
    font-weight: 700;
  }
  
  .rightSection form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup label {
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .formGroup input,
  .formGroup textarea {
    padding: 16px;
    border: 1.5px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .submitButton {
    padding: 16px;
    background-color: #FF5A67;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submitButton:hover {
    background-color: rgb(202, 60, 72);
  }
  
  @media (max-width: 768px) {
    .contactUsPage {
      flex-direction: column;
    }
  
    .leftSection,
    .rightSection {
      max-width: 95%;
    }
  }