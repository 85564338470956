.container {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .leftSection {
    flex: 1;
    background-color: #fff7f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
  }
  
  .leftSection h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .leftSection p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  .form {
    width: 100%;
    max-width: 400px;
  }
  
  .input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  
  .button {
    width: 100%;
    padding: 0.75rem;
    background-color: #ff6b6b;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #ff4c4c;
  }
  
  .rightSection {
    flex: 1;
    background-color: #f5f9ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
  }
  
  .imageContainer {
    margin-bottom: 1.5rem;
  }
  
  .image {
    max-width: 100%;
    height: auto;
  }
  
  .text h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .text p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .link {
    color: #ff6b6b;
    text-decoration: none;
    font-weight: bold;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .closeButton {
    background: none;
    color: #333;
    font-size: 0.9rem;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
  }