
.container {
  background: #fff0f0;
 border-radius: 10px;
 min-height: 100vh;
  text-align: center;
}

.heads{
font-size: 1.5rem; 
font-weight: bolder;
text-align: start;
}

.title {
 font-size: 40px;
 font-weight: bold;
 color: #242424;
}

.content {
 display: flex;
 justify-content: space-between;
 align-items: flex-start;
 gap: 20px;
 margin-top: 20px;
}

.leftPanel {
 flex: 1;
 background: #FFDAD9; 
 padding: 15px;
 border-radius: 10px;
 box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.rightPanel {
flex: 1;
padding: 15px;
border-radius: 10px;
}

.uploadBox {
  padding: 20px;
  border-radius: 10px;
  background: #fff5f5;
  display: flex
;
  justify-content: center;
}

.fileInput {
 display: block;
 margin: auto;
 padding: 10px;
 cursor: pointer;
}

.imagePreview {
 width: 100%;
 max-height: 250px;
 object-fit: contain;
 margin-top: 10px;
 border-radius: 5px;
}

.uploadButton {
background-color: #ff6b6b;
color: white;
border: none;
padding: 10px 20px;
border-radius: 5px;
cursor: pointer;
font-size: 16px;
}

.uploadButton:hover {
background-color: #ff4f4f;
}

.convertButton, .translateButton, .downloadButton {
 padding: 10px 20px;
 background: #FF5A67;
 color: white;
 border: none;
 border-radius: 9px;
 cursor: pointer;
 transition: background 0.3s;
}

.translateButton {
margin-bottom: 20px;
}

.convertButton {
margin-top: 15px;
}

.convertButton:hover, .translateButton:hover, .downloadButton:hover {
 background: #e04854;
}

.textBox, .translationBox {
 margin-top: 15px;
}

.textArea {
 width: 100%;
 height: 100px;
 padding: 10px;
 border: none;
 border-radius: 5px;
 font-size: 14px;
}

.languageSelect {
 width: 100%;
 padding: 20px;
 border: none;
 border-radius: 5px;
 margin-bottom: 10px;
}

.downloadButtons {
 display: flex;
 margin-top: 10px;

 justify-content: flex-end;
  gap: 10px
}



/* ============== RESPONSIVE DESIGN ============== */



@media (max-width: 1024px) {
.content {
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container {
  width: 100%;
  min-height: 100vh; 
}

.leftPanel,
.rightPanel {
  width: 100%;
}
}

@media (max-width: 768px) {
.container {
  width: 100%;
  min-height: 100vh; 
}

.leftPanel,
.rightPanel {
  width: 100%;
}
}


@media (max-width: 576px) {
.container {
  padding: 20px;
}

.title {
  font-size: 30px;
}

.uploadBox {
  padding: 20px;
}

.uploadButton {
  padding: 8px 15px;
  font-size: 14px;
}

.convertButton,
.translateButton,
.downloadButton {
  padding: 8px;
  font-size: 12px;
  width: 40%;
}

.textArea {
  height: 80px;
  font-size: 12px;
}

.languageSelect {
  padding: 10px;
  font-size: 14px;
}

.downloadButtons {
  flex-direction: column;
  align-items: center;
  width: 100%;
}
}

@media (max-width: 400px) {
.title {
  font-size: 25px;
}

.heads {
  font-size: 1rem;
}

.convertButton,
.translateButton,
.downloadButton {
  padding: 6px;
  font-size: 11px;
}

.textArea {
  height: 70px;
}

.uploadBox {
  padding: 15px;
}
}
