.container {
    width: 100%;
     margin: 20px auto;
    padding: 20px;
    background: white;
    border-radius: 10px;
   }
  
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .tableWrapper {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    background: white;
  }
  
  .table th,
  .table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .table th {
    background: #f8f8f8;
    font-weight: bold;
  }
  
  .table tr:last-child td {
    border-bottom: none;
  }
  
  /* Status Colors */
  .active {
    color: green;
    font-weight: bold;
  }
  
  .expired {
    color: red;
    font-weight: bold;
  }
  
  .canceled {
    color: orange;
    font-weight: bold;
  }
  
  /* Button Styles */
  .detailsBtn {
    background: #4caf50;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .detailsBtn:hover {
    background: #45a049;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .table th,
    .table td {
      font-size: 14px;
      padding: 8px;
    }
  
    .detailsBtn {
      font-size: 12px;
      padding: 5px 10px;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 20px;
    }
  
    .table {
      font-size: 12px;
    }
  
    .table th,
    .table td {
      padding: 6px;
    }
  
    .detailsBtn {
      font-size: 10px;
      padding: 4px 8px;
    }
  }
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
  }
  
  .closeBtn {
    background-color: red;
    color: white;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
  }
  
  .closeBtn:hover {
    background-color: darkred;
  }
  