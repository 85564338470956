.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 50px auto;
}

.step-container {
  display: flex;
  align-items: center;
  position: relative;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid gray;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: gray;
  position: relative;
  z-index: 2;
}

.active {
  border-color: #ff6e6c;
  background-color: #ff6e6c;
  color: white;
}

.line {
  width: 203px;
  height: 4px;
  background-color: gray;
  position: absolute;
  top: 50%;
  left: 100%;
  z-index: 1;
  transform: translateY(-50%);
}

.step-label {
  position: absolute;
  top: 50px;
  text-align: center;
  width: max-content;
  font-size: 14px;
}
@media (max-width: 1000px) {
  .stepper {
    flex-direction: column;
    align-items: center;
  }

  .step-container {
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px; /* Add spacing between steps */
  }

  .circle {
    margin-bottom: 10px;
  }

  .line {
    display: none;
  }

  .step-label {
    position: relative;
    top: auto;
    margin-top: 5px;
  }
}