.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    color: white;
  }
  
  .loginCard {
    background: white;
    width: 100%;
    max-width: 400px;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .loginHeading {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .loginForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .loginLabel {
    text-align: left;
    font-weight: bold;
    color: #666;
  }
  
  .loginInput {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.2s;
  }
  
  .loginInput:focus {
    border-color: #6a11cb;
  }
  
  .loginButton {
    padding: 12px;
    background: linear-gradient(90deg, #6a11cb, #2575fc);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .loginButton:hover {
    background: linear-gradient(90deg, #2575fc, #6a11cb);
  }
  
  .loginMessage {
    margin-top: 15px;
    font-size: 1rem;
    color: yellow;
  }
  