.container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .heading {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .table th {
    background-color: #f4f4f4;
  }
  
  .actionButton {
    margin: 0 5px;
    padding: 5px 10px;
    background: #6c63ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .actionButton:hover {
    background: #5848cc;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 60%;
    max-height: 80%;
    overflow-y: auto;
    text-align: left;
  }
  
  .blogContent {
    margin-top: 15px;
    line-height: 1.6;
    font-size: 1rem;
    color: #333;
  }
  
  .closeButton {
    margin-top: 20px;
    padding: 10px 20px;
    background: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .closeButton:hover {
    background: darkred;
  }
  .actionButton {
    margin: 0 5px;
    padding: 5px 10px;
    background: #6c63ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .actionButton:hover {
    background: #5848cc;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 50%;
    text-align: center;
  }
  
  .inputField {
    width: 90%;
    margin: 10px 0;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .saveButton {
    margin-top: 15px;
    padding: 10px 20px;
    background: green;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .saveButton:hover {
    background: darkgreen;
  }
  
  .closeButton {
    margin-top: 15px;
    padding: 10px 20px;
    background: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .closeButton:hover {
    background: darkred;
  }
  .fileInput {
    display: block;
    margin-top: 5px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .imagePreviewContainer {
    margin-top: 10px;
    text-align: center;
  }
  
  .imagePreview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  